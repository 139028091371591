import { useSelector } from 'react-redux';
import PageLayout from '../subcomponents/PageLayout';
import HistoryDisplay from './HistoryDisplay';

export default function HistoryPage() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.HISTORY} content={
            user && <HistoryDisplay studentId={user?.studentId} />
        } bottomBar />
    );
}
