import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { CardMedium, HomeTileSmall, TableHeader } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import { fetchEmployeeSchoolclasses } from './employeeSlice';
export default function EmployeeSchoolclassesTile() {
    const dispatch = useDispatch();

    const { employeeSchoolclasses, employeeSchoolclassesStatus } = useSelector((state) => state.employees);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        dispatch(fetchEmployeeSchoolclasses());
    }, []);

    const TableFragment = () => {
        return (
            <>
                <TableBody>
                    {employeeSchoolclasses?.slice(0, 10).map(item =>
                        <TableRow key={item.schoolclassId} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">
                                <Link to={`/zoeken/klas/${item.schoolclassId}`} style={{ color: 'black' }}>
                                    {item.schoolclassName}
                                </Link>
                            </TableCell>
                        </TableRow>
                    )}
                    {employeeSchoolclasses.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                </TableBody>
            </>
        );
    };

    return (
        <Box sx={{ ...HomeTileSmall }}>
            <Box sx={{ ...CardMedium, display: 'flex', width: 1, height: 1, boxSizing: 'border-box', alignItems: 'flex-start' }}>
                <ContentLoader successCondition={employeeSchoolclassesStatus === "success"} errorCondition={employeeSchoolclassesStatus === "failed"} textDisabled errorCompact content={
                    <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CLASS}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableFragment />
                            </Table>
                        </TableContainer>
                    </Box>
                } />
            </Box>
        </Box>
    );
}
