import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium } from '../../../../app/AppStyles';
import ContentLoader from '../../../subcomponents/ContentLoader';
import TimetableDisplay from '../../../timetable/TimetableDisplay';
import { fetchSearchStudentTimetable } from '../searchStudentSlice';

export default function SearchStudentTimetableTab({ studentId }) {
    const dispatch = useDispatch();

    const { searchStudentTimetable, searchStudentTimetableStatus } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    // Get student timetable data
    useEffect(() => {
        var departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null

        dispatch(fetchSearchStudentTimetable({ studentId: studentId, departmentId: departmentId }));
    }, [dispatch, siomSettings, activeDepartment, studentId]);

    return (
        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
            <ContentLoader successCondition={searchStudentTimetableStatus === 'success'} errorCondition={searchStudentTimetableStatus === 'failed'} content={
                <TimetableDisplay timetable={searchStudentTimetable} employeeMode />
            } />
        </Card>
    );
}
