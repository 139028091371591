import { Avatar, Box, Card, CardActionArea, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBgcolor, CardLarge, MainBgcolor } from '../../app/AppStyles';
import { GetDateLong, GetTime } from "../../app/AppUtils";
import ContentLoader from '../subcomponents/ContentLoader';
import IconDisplay from '../subcomponents/IconDisplay';
import NoContentDisplay from '../subcomponents/NoContentDisplay';
import ScheduleDetails from './ScheduleDetails';
import { fetchSchedule, setMomentDetails } from './scheduleSlice';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EventIcon from '@mui/icons-material/Event';

export default function ScheduleDisplayAlt({ employeeMode }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { schedule, scheduleStatus, momentDetails } = useSelector((state) => state.schedule);
    const { searchStudentSchedule, searchStudentScheduleStatus } = useSelector((state) => state.search.student);
    const { enrollStatus, deleteStatus } = useSelector((state) => state.register);
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { departmentThemes } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);

    const desktop = useMediaQuery('(min-width:900px)');
    const [scheduleContent, setScheduleContent] = useState();

    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchSchedule());
        };
    }, [dispatch, aspNetUser, enrollStatus, deleteStatus]);

    useEffect(() => {
        if (scheduleStatus === "success")
            setScheduleContent(schedule);

        if (searchStudentScheduleStatus === "success" && employeeMode)
            setScheduleContent(searchStudentSchedule);
    }, [scheduleStatus, searchStudentScheduleStatus, employeeMode]);

    useEffect(() => {
        if (scheduleContent) {
            dispatch(setMomentDetails(scheduleContent[0]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleContent])

    const detailsClick = (id) => {
        if (desktop) {
            dispatch(setMomentDetails(scheduleContent.find((element) => element.momentId === id)));
        } else {
            navigate(`/agenda/${id}`);
        };
    };

    const ListFragment = ({ icon, label, text }) => {
        return (
            <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    secondary={label}
                />
            </ListItem>
        );
    };

    return (
        <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
            <ContentLoader successCondition={(scheduleStatus === "success" || searchStudentScheduleStatus === "success") && scheduleContent} errorCondition={scheduleStatus === "failed" || searchStudentScheduleStatus === "failed"} style={{ color: 'white.main' }} content={
                <Grid container spacing={1} sx={{ display: 'flex', flex: '1 1 auto' }}>
                    {scheduleContent?.length > 0 ?
                        <>
                            <Grid size={{ xs: desktop ? 5 : 12 }}>
                                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
                                    <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                                        {scheduleContent?.map((item) =>
                                            <Card key={item.momentId} elevation={0} sx={{ ...CardLarge, bgcolor: momentDetails?.momentId === item.momentId ? MainBgcolor : CardBgcolor }}>
                                                <CardActionArea sx={{ p: 1 }} onClick={() => detailsClick(item.momentId)}>
                                                    <Grid container>
                                                        <Grid size={{ xs: 11 }}>
                                                            <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 0.5 }}>
                                                                <Avatar sx={{ bgcolor: siomSettings.useMultipleDepartments ? departmentThemes?.filter(x => x.id === item.departmentId)[0]?.themeSettings.primaryColor : 'secondary.main' }}>
                                                                    <IconDisplay component={item.activityIcon} style={{ color: 'white.main' }} />
                                                                </Avatar>
                                                                <Typography noWrap sx={{
                                                                    color: siomSettings.useMultipleDepartments ? departmentThemes?.filter(x => x.id === item.departmentId)[0]?.themeSettings.primaryColor : 'secondary.main',
                                                                    fontWeight: 'bold', cursor: 'pointer'
                                                                }}>{item.activity}</Typography>
                                                                {item.isReservation && <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>{resourceFinal[appLanguage].words.RESERVATION}</Typography>}
                                                            </Stack>
                                                            <List dense sx={{ p: 0 }}>
                                                                <ListFragment icon={<EventIcon sx={{ p: 1, color: siomSettings.useMultipleDepartments ? departmentThemes?.filter(x => x.id === item.departmentId)[0]?.themeSettings.primaryColor : 'secondary.main' }} />}
                                                                    label={
                                                                        appSettings?.showSchedulePeriod ?
                                                                            `${resourceFinal[appLanguage].words.DATE}, ${resourceFinal[appLanguage].words.TIME} & ${resourceFinal[appLanguage].words.PERIOD}` :
                                                                            `${resourceFinal[appLanguage].words.DATE} & ${resourceFinal[appLanguage].words.TIME}`
                                                                    }
                                                                    text={
                                                                        appSettings?.showSchedulePeriod ?
                                                                            `${GetDateLong(item.startDate)} | ${GetTime(item.startDate)} - ${GetTime(item.endDate)} | ${item.period.name}` :
                                                                            `${GetDateLong(item.startDate)} | ${GetTime(item.startDate)} - ${GetTime(item.endDate)}`
                                                                    } item={item} />
                                                            </List>
                                                        </Grid>
                                                        <Grid size={{ xs: 1 }} >
                                                            <Box sx={{ display: 'flex', height: 1 }}>
                                                                <ArrowForwardIosIcon
                                                                    sx={{ alignSelf: 'center', color: siomSettings.useMultipleDepartments ? departmentThemes?.filter(x => x.id === item?.departmentId)[0]?.themeSettings.primaryColor : 'secondary.main' }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardActionArea>
                                            </Card>
                                        )}
                                    </Stack>
                                </Scrollbars>
                            </Grid>
                            <Grid size={{ xs: desktop ? 7 : 0 }} sx={{ display: desktop ? 'flex' : 'none' }}>
                                <Card sx={{ ...CardLarge }}>
                                    <ContentLoader successCondition={momentDetails !== null} errorCondition={''} content={
                                        <ScheduleDetails scrollText employeeMode={employeeMode} />
                                    } />
                                </Card>
                            </Grid>
                        </>
                        :
                        <Grid size={{ xs: "grow" }}>
                            <NoContentDisplay message={resourceFinal[appLanguage].messages.NO_MOMENTS} />
                        </Grid>
                    }
                </Grid>
            } />
        </Box >
    );
}
