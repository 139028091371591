import { Box, Button, ButtonGroup, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import { getAvailableActivities, getSubprogramActivities, postSubprogramActivity, setFailedSubprogramActivity } from './subprogramManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

const emptySubprogramActivity = {
    subprogramId: null,
    activityIds: [],
    minSubscriptions: null,
    maxSubscriptions: null,
    maxAbsence: null,
    createdBy: null
};

export default function SubprogramActivityAddCard({ handleClose }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedSubprogram, postSubprogramActivitiesStatus, showSubprogramActivitiesFailed, availableActivities, availableActivitiesStatus } = useSelector((state) => state.management.subprogram);

    // Property states
    const [newSubprogramActivity, setNewSubprogramActivity] = useState(emptySubprogramActivity);
    const [changed, setChanged] = useState(false);
    const [emptyActivitiesError, setEmptyActivitiesError] = useState(false);
    const [emptyError, setEmptyError] = useState(false);
    const [triedSubmit, setTriedSubmit] = useState(false);
    const [filter, setFilter] = useState("");
    const [newActivityIds, setNewActivityIds] = useState([]);

    // Get subprograms
    useEffect(() => {
        dispatch(getAvailableActivities(selectedSubprogram.id));
    }, [dispatch, selectedSubprogram]);

    // Post status callback
    useEffect(() => {
        if (postSubprogramActivitiesStatus === "success") {
            dispatch(setFailedSubprogramActivity(false));
            dispatch(getSubprogramActivities(selectedSubprogram.id));
            handleClose();
        } else if (postSubprogramActivitiesStatus === "failed") {
            dispatch(setFailedSubprogramActivity(true));
        };
    }, [dispatch, handleClose, selectedSubprogram, postSubprogramActivitiesStatus]);

    // Keep track of changed state 
    useEffect(() => {
        if (newSubprogramActivity !== emptySubprogramActivity)
            setChanged(true);
        else
            setChanged(false);
    }, [newSubprogramActivity]);

    // Check activities are empty
    useEffect(() => {
        if (triedSubmit && newActivityIds.length === 0)
            setEmptyActivitiesError(true);
        else
            setEmptyActivitiesError(false);
    }, [newActivityIds, triedSubmit]);

    // Check properties are empty
    useEffect(() => {
        if (triedSubmit && (newSubprogramActivity.minSubscriptions === null || newSubprogramActivity.maxSubscriptions === null || newSubprogramActivity.maxAbsence === null))
            setEmptyError(true);
        else
            setEmptyError(false);
    }, [newSubprogramActivity, triedSubmit]);

    // Add click function
    const addClick = () => {
        if (newActivityIds.length === 0) {
            setTriedSubmit(true);
            return;
        };

        if (newSubprogramActivity.minSubscriptions === null || newSubprogramActivity.maxSubscriptions === null || newSubprogramActivity.maxAbsence === null) {
            setTriedSubmit(true);
            return;
        };

        newSubprogramActivity.activityIds = newActivityIds;
        newSubprogramActivity.subprogramId = selectedSubprogram.id;
        newSubprogramActivity.createdBy = `AP/${user.number}`;

        dispatch(postSubprogramActivity(newSubprogramActivity));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["name"], filter);
    }

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < availableActivities?.filter(handleFilterResult).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === availableActivities?.filter(handleFilterResult).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = availableActivities?.filter(handleFilterResult).map((n) => n.id);
            setNewActivityIds(newSelected);
            return;
        };

        setNewActivityIds([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newActivityIds.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newActivityIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newActivityIds.slice(1));
        } else if (selectedIndex === newActivityIds.length - 1) {
            newSelected = newSelected.concat(newActivityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newActivityIds.slice(0, selectedIndex),
                newActivityIds.slice(selectedIndex + 1),
            );
        };

        setNewActivityIds(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - availableActivities?.filter(handleFilterResult).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => newActivityIds.findIndex(x => x === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.ACTIVITIES} {resourceFinal[appLanguage].words.LINK2.toLowerCase()}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 0 }}
                                error={newSubprogramActivity.minSubscriptions === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.SUBSCRIPTIONS_MIN}
                                onChange={(event) => setNewSubprogramActivity({
                                    ...newSubprogramActivity,
                                    minSubscriptions: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 1 }}
                                error={newSubprogramActivity.maxSubscriptions === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.SUBSCRIPTIONS_MAX}
                                onChange={(event) => setNewSubprogramActivity({
                                    ...newSubprogramActivity,
                                    maxSubscriptions: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={{ xs: siomSettings?.flagProgress ? 6 : 12 }}>
                            <TextField
                                fullWidth required
                                type="number"
                                inputProps={{ min: 0 }}
                                error={newSubprogramActivity.maxAbsence === null && triedSubmit}
                                label={resourceFinal[appLanguage].words.ABSENCE_MAX}
                                onChange={(event) => setNewSubprogramActivity({
                                    ...newSubprogramActivity,
                                    maxAbsence: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ContentLoader successCondition={availableActivitiesStatus === "success"} errorCondition={availableActivitiesStatus === "failed"} content={
                                <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                                    <SearchField func={handleSearchChange} textRef={textRef} />
                                    <TableContainer>
                                        <Table>
                                            <EnhancedTableHead numSelected={newActivityIds?.length} onSelectAllClick={handleSelectAllClick} rowCount={availableActivities?.length} />
                                            <TableBody>
                                                {(rowsPerPage > 0 ? availableActivities?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableActivities?.filter(handleFilterResult))?.map((item, index) => {
                                                    const isItemSelected = isSelected(item.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow hover onClick={(event) => handleClick(event, item.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.id} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="primary" checked={isItemSelected ?? ""} inputProps={{ 'aria-labelledby': labelId, }} />
                                                            </TableCell>
                                                            <TableCell align="left">{item.name}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {(rowsPerPage > 0 ? availableActivities?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableActivities?.filter(handleFilterResult))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                                {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        sx={{ border: 0 }}
                                                        rowsPerPageOptions={[10, 25, 50,
                                                            {
                                                                label: availableActivities?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                                                value: availableActivities?.filter(handleFilterResult).length < 100 ? -1 : 100
                                                            }
                                                        ]}
                                                        count={availableActivities ? availableActivities?.length : 0}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            } />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            {(emptyActivitiesError && triedSubmit) && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROGRAM_MANAGEMENT_EMPTY_ACTIVITIES}</Typography>}
                            {(emptyError && triedSubmit) && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_ANY_EMPTY}</Typography>}
                            {showSubprogramActivitiesFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed || emptyActivitiesError || emptyError} variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
