import { Avatar, Box, Card, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TableHeader } from '../../app/AppStyles';
import { GetDate, GetDateLong, GetTime } from '../../app/AppUtils';
import { deleteSubscription } from '../planning/subscriptions/subscriptionSlice';
import { deleteSubscriptionStudent, resetRegisterStatusses } from '../register/registerSlice';
import ConfirmationModal from '../subcomponents/ConfirmationModal';
import GridCard from '../subcomponents/GridCard';
import IconDisplay from '../subcomponents/IconDisplay';
import NoContentDisplay from '../subcomponents/NoContentDisplay';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import Scrollbars from 'react-custom-scrollbars-2';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: 'auto',
    maxHeight: '90%',
    minWidth: '60%',
    maxWidth: '90%',
    boxShadow: 24,
};

export default function ProgressActivities({ progressActivities, employeeMode, employeeStudentId, historyMode }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appSettings } = useSelector((state) => state.settings.app);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);

    const [openHistory, setOpenHistory] = useState(false);
    const [historyActivity, setHistoryActivity] = useState(null);

    const deleteClick = (item) => {
        if (employeeMode) {
            dispatch(deleteSubscription([{
                studentId: employeeStudentId,
                subscriptionId: item.subscriptionId,
                performanceId: item.performanceId,
                createdBy: `AP/${user.number}`
            }]));
        } else {
            dispatch(deleteSubscriptionStudent({
                studentId: user.studentId,
                subscriptionId: item.subscriptionId,
                performanceId: item.performanceId,
                createdBy: `AP/${user.number}`
            }));

            dispatch(resetRegisterStatusses());
        };
    };

    const handleSubscriptionClick = (name, id) => {
        navigate(`/voortgang/inschrijving/${encodeURIComponent(name)}/${id}`);
    };

    const handleHistoryClick = (activity) => {
        setOpenHistory(true);
        setHistoryActivity(activity);
    };

    const handleHistoryClose = () => {
        setOpenHistory(false);
        setHistoryActivity(null);
    };

    return (
        <>
            {progressActivities.length > 0 ? (
                <>
                    <TableContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" component="th"></TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERIOD}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.FIRST} {resourceFinal[appLanguage].words.MOMENT}</TableCell>
                                    {!siomSettings?.flagProgress && <TableCell align="center" sx={{ ...TableHeader }}>{appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}</TableCell>}
                                    {appSettings?.appVersion === 1 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.COURSE}</TableCell>}
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PRESENCE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STATUS}</TableCell>
                                    {!historyMode && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.UNSUBSCRIBE}</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {progressActivities.map((item) =>
                                    <TableRow key={item.performanceId}>
                                        <TableCell align="center" padding="checkbox">
                                            <IconButton disabled={employeeMode} onClick={() => handleSubscriptionClick(item.activityName, item.performanceId)}>
                                                <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                                    <IconDisplay component={item.activityIconName} style={{ color: 'white.main' }} />
                                                </Avatar>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="left" sx={{ textDecoration: !employeeMode && 'underline', cursor: !employeeMode && 'pointer' }} onClick={() => !employeeMode && handleSubscriptionClick(item.activityName, item.performanceId)}>{item.activityName} {item.performanceName && `| ${item.performanceName}`}  {item.isReservation && <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>{resourceFinal[appLanguage].words.RESERVATION}</Typography>}</TableCell>
                                        <TableCell align="left">{item.periodName}</TableCell>
                                        <TableCell align="center">{`${GetDate(item.firstDate)} ${GetTime(item.firstDate)}`}</TableCell>
                                        {!siomSettings?.flagProgress && <TableCell align="center" padding="checkbox">{item.pointsDisplay}</TableCell>}
                                        {appSettings?.appVersion === 1 && <TableCell align="center">{item.momentsProgressDisplay}</TableCell>}
                                        <TableCell align="center">
                                            {appSettings?.appVersion === 1 ? item.attendanceDisplay :
                                                item.attendanceDisplay.split("/")[1] === "0" ? "-" :
                                                    item.attendanceDisplay.split("/")[0] < item.attendanceDisplay.split("/")[1] ? resourceFinal[appLanguage].words.NO :
                                                        resourceFinal[appLanguage].words.YES}
                                        </TableCell>
                                        <TableCell align="center" padding="checkbox">{item.status ? <CheckCircleIcon color="success" /> : <ReportIcon color="error" />}</TableCell>
                                        {!historyMode && <TableCell align="center" padding="checkbox">
                                            <ConfirmationModal
                                                iconButton={true}
                                                icon={<DeleteIcon />}
                                                buttonColor="error"
                                                disabledCondition={
                                                    employeeMode ?
                                                        (user.onlyEmployee ? true : false)
                                                        :
                                                        !item.unsubscribable}
                                                message={`${resourceFinal[appLanguage].messages.UNSUBSCRIBE_CONFIRMATION} ${item.activityName}?`}
                                                confirmAction={() => deleteClick(item)} />
                                        </TableCell>}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {progressActivities.map((item, index) =>
                            <Grid size={{ xs: 12 }} key={index}>
                                <GridCard
                                    actionClick={() => historyMode ? handleHistoryClick(item) : (employeeMode ? deleteClick(item) : handleSubscriptionClick(item.activityName, item.performanceId))}
                                    actionIcon={employeeMode ? <DeleteIcon color="secondary" /> : <ArrowForwardIosIcon color="secondary" />}
                                    avatarIcon={<IconDisplay component={item.activityIconName} style={{ color: 'white.main' }} />}
                                    avatarBgcolor='secondary.main'
                                    textPrimary={item.activityName}
                                    textSecondary={GetDateLong(item.firstDate)}
                                    textTertiary={item.isReservation && resourceFinal[appLanguage].words.RESERVATION}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Modal open={openHistory} onClose={handleHistoryClose}>
                        <Box sx={{ ...modalStyle }}>
                            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                                    <List>
                                        <ListItem>
                                            <Stack>
                                                <ListItemIcon>
                                                    <IconDisplay component={historyActivity?.activityIconName} style={{ color: 'secondary.main' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`${historyActivity?.activityName} ${historyActivity?.performanceName ? (" | " + historyActivity?.performanceName) : ""}`}
                                                    secondary={resourceFinal[appLanguage].words.ACTIVITY}
                                                />
                                                <ListItemText
                                                    primary={historyActivity?.periodName}
                                                    secondary={resourceFinal[appLanguage].words.PERIOD}
                                                />
                                                <ListItemText
                                                    primary={`${GetDate(historyActivity?.firstDate)} ${GetTime(historyActivity?.firstDate)}`}
                                                    secondary={`${resourceFinal[appLanguage].words.FIRST} ${resourceFinal[appLanguage].words.MOMENT}`}
                                                />
                                                {!siomSettings?.flagProgress && <ListItemText
                                                    primary={historyActivity?.pointsDisplay}
                                                    secondary={appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}
                                                />}
                                                <ListItemText
                                                    primary={historyActivity?.momentsProgressDisplay}
                                                    secondary={resourceFinal[appLanguage].words.COURSE}
                                                />
                                                <ListItemText
                                                    primary={appSettings?.appVersion === 1 ? historyActivity?.attendanceDisplay :
                                                        historyActivity?.attendanceDisplay.split("/")[1] === "0" ? "-" :
                                                            historyActivity?.attendanceDisplay.split("/")[0] < historyActivity?.attendanceDisplay.split("/")[1] ? resourceFinal[appLanguage].words.NO :
                                                                resourceFinal[appLanguage].words.YES}
                                                    secondary={resourceFinal[appLanguage].words.PRESENCE}
                                                />
                                                <ListItemText
                                                    primary={historyActivity?.status ? <CheckCircleIcon color="success" /> : <ReportIcon color="error" />}
                                                    secondary={resourceFinal[appLanguage].words.STATUS}
                                                />
                                            </Stack>
                                        </ListItem>
                                    </List>
                                </Scrollbars>
                            </Card>
                        </Box>
                    </Modal>
                </>
            ) : (
                <NoContentDisplay message={`${historyMode ? resourceFinal[appLanguage].messages.HISTORY_NO_SUBSCRIPTIONS : resourceFinal[appLanguage].messages.NO_CURRENT_CLINICS}`} />
            )}
        </>
    );
}
