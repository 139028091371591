import { alpha, Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { fetchExternalConnectionSettings, putSetting, resetUpdateStatus } from './adminSlice';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ExternalConnectionsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { externalConnections, externalConnectionsStatus, updateSettingStatus } = useSelector((state) => state.admin);

    // Hidden states
    const [showMollieToken, setShowMollieToken] = useState(false);
    const [showAzureConnectionString, setShowAzureConnectionString] = useState(false);
    const [showAzureSasToken, setshowAzureSasToken] = useState(false);
    const [showSmtpPassword, setShowSmtpPassword] = useState(false);
    const [showSmtpPasswordSendGrid, setShowSmtpPasswordSendGrid] = useState(false);

    // Fetch external connection settings first time
    useEffect(() => {
        dispatch(fetchExternalConnectionSettings());
    }, []);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchExternalConnectionSettings());
        } else if (updateSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus]);

    // Mollie active
    const [newMollieActive, setNewMollieActive] = useState(null);

    useEffect(() => {
        if (newMollieActive !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Activate_Mollie",
                value: newMollieActive ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieActive]);

    // Mollie delay
    const [newMollieDelay, setNewMollieDelay] = useState(null);

    useEffect(() => {
        if (newMollieDelay !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Enable_Delay",
                value: newMollieDelay ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieDelay]);

    // Mollie hours
    const [newMollieHours, setNewMollieHours] = useSearchDebounce();

    useEffect(() => {
        if (newMollieHours) {
            dispatch(putSetting({
                key: "Siom_Mollie_Delay_Hours",
                value: newMollieHours,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieHours]);

    // Mollie unsubscribe
    const [newMollieUnsub, setNewMollieUnsub] = useState(null);

    useEffect(() => {
        if (newMollieUnsub !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Enable_Unsubscribe",
                value: newMollieUnsub ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieUnsub]);

    // Mollie test
    const [newMollieTest, setNewMollieTest] = useState(null);

    useEffect(() => {
        if (newMollieTest !== null) {
            dispatch(putSetting({
                key: "Siom_Mollie_Activate_Mollie_TestModus",
                value: newMollieTest ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieTest]);

    // Mollie token
    const [newMollieToken, setNewMollieToken] = useSearchDebounce();

    useEffect(() => {
        if (newMollieToken) {
            dispatch(putSetting({
                key: "Storage_ContainerName",
                value: newMollieToken,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMollieToken]);

    // Storage SAS Token
    const [NewSasToken, setNewSasToken] = useSearchDebounce();

    useEffect(() => {
        if (NewSasToken) {
            dispatch(putSetting({
                key: "Storage_PublicContainerReadOnlySasToken",
                value: NewSasToken,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, NewSasToken]);


    // Storage connection string
    const [newStorageConnectionString, setNewStorageConnectionString] = useSearchDebounce();

    useEffect(() => {
        if (newStorageConnectionString) {
            dispatch(putSetting({
                key: "Storage_connectionString",
                value: newStorageConnectionString,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newStorageConnectionString]);

    // Storage container name
    const [newStorageContainerName, setNewStorageContainerName] = useSearchDebounce();

    useEffect(() => {
        if (newStorageContainerName) {
            dispatch(putSetting({
                key: "Storage_ContainerName",
                value: newStorageContainerName,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newStorageContainerName]);

    // Storage StoragePrimaryUri
    const [newStoragePrimaryUri, setNewStoragePrimaryUri] = useSearchDebounce();

    useEffect(() => {
        if (newStoragePrimaryUri) {
            dispatch(putSetting({
                key: "Storage_PublicContainerPrimaryUri",
                value: newStoragePrimaryUri,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newStoragePrimaryUri]);

    // Email SMTP SSL
    const [newEmailSMTPSSL, setNewEmailSMTPSSL] = useState(null);

    useEffect(() => {
        if (newEmailSMTPSSL !== null) {
            dispatch(putSetting({
                key: "Email_SmtpEnableSsl",
                value: newEmailSMTPSSL ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSMTPSSL]);

    // Email SMTP Host
    const [newEmailSMTPHost, setNewEmailSMTPHost] = useSearchDebounce();

    useEffect(() => {
        if (newEmailSMTPHost) {
            dispatch(putSetting({
                key: "Email_SmtpHost",
                value: newEmailSMTPHost,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSMTPHost]);


    // Email SMTP password
    const [newEmailSMTPPassword, setNewEmailSMTPPassword] = useSearchDebounce();

    useEffect(() => {
        if (newEmailSMTPPassword) {
            dispatch(putSetting({
                key: "Email_SmtpPassword",
                value: newEmailSMTPPassword,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSMTPPassword]);

    // Email SMTP password for SendGrid
    const [newEmailSmtpPasswordSendGrid, setEmailSmtpPasswordSendGrid] = useSearchDebounce();

    useEffect(() => {
        if (newEmailSmtpPasswordSendGrid) {
            dispatch(putSetting({
                key: "Email_SmtpPassword_SendGrid",
                value: newEmailSmtpPasswordSendGrid,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSmtpPasswordSendGrid]);

    // Email SMTP port
    const [newEmailSMTPPort, setNewEmailSMTPPort] = useSearchDebounce();

    useEffect(() => {
        if (newEmailSMTPPort) {
            dispatch(putSetting({
                key: "Email_SmtpPort",
                value: newEmailSMTPPort,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSMTPPort]);

    // Email SMTP usename
    const [newEmailSMTPUseName, setNewEmailSMTPUseName] = useSearchDebounce();

    useEffect(() => {
        if (newEmailSMTPUseName) {
            dispatch(putSetting({
                key: "Email_SmtpUsename",
                value: newEmailSMTPUseName,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmailSMTPUseName]);

    return (
        <ContentLoader successCondition={externalConnectionsStatus === "success"} errorCondition={externalConnectionsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={1}>
                    <Grid container spacing={1} size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.primary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* MOLLIE */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.EXTCON_TITLE_MOLLIE}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_ACTIVE}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={externalConnections?.mollieActive} onChange={(e) => setNewMollieActive(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_ACTIVE_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_TEST}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox disabled={!externalConnections?.mollieActive} defaultChecked={externalConnections?.mollieTest} onChange={(e) => setNewMollieTest(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_TEST_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_SIOM_MOLLIE_DELAY}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox disabled={!externalConnections?.mollieActive} defaultChecked={externalConnections?.mollieEnableDelay} onChange={(e) => setNewMollieDelay(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.EXTCON_SIOM_MOLLIE_DELAY_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_HOURS}</FormLabel>
                                <TextField disabled={!externalConnections?.mollieActive || !externalConnections?.mollieEnableDelay}
                                    fullWidth type="number"
                                    defaultValue={externalConnections?.mollieDelayHours}
                                    inputProps={{ min: 1 }}
                                    onChange={(e) => setNewMollieHours(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_MOLLIE_HOURS_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_UNSUB}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox disabled={!externalConnections?.mollieActive} defaultChecked={externalConnections?.mollieEnableUnsubscribe} onChange={(e) => setNewMollieUnsub(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_UNSUB_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_MOLLIE_TOKEN}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={showMollieToken ? "text" : "password"}
                                    defaultValue={externalConnections?.mollieToken}
                                    onChange={(e) => {
                                        setNewMollieToken(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_MOLLIE_TOKEN_HTEXT}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowMollieToken(!showMollieToken)}
                                                edge="end"
                                            >
                                                {showMollieToken ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.secondary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* STORAGE */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.EXTCON_TITLE_STORAGE}</Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_STORAGE_CONNECTION_STRING}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={showAzureConnectionString ? "text" : "password"}
                                    defaultValue={externalConnections?.storageConnectionString}
                                    onChange={(e) => {
                                        setNewStorageConnectionString(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_STORAGE_CONNECTION_STRING_HTEXT}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowAzureConnectionString(!showAzureConnectionString)}
                                                edge="end"
                                            >
                                                {showAzureConnectionString ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_STORAGE_SAS_TOKEN}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={showAzureSasToken ? "text" : "password"}
                                    defaultValue={externalConnections?.storageSasToken}
                                    onChange={(e) => {
                                        setNewSasToken(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_STORAGE_SAS_TOKEN_HTEXT}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setshowAzureSasToken(!showAzureSasToken)}
                                                edge="end"
                                            >
                                                {showAzureSasToken ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_STORAGE_PRIMARY_URI}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={externalConnections?.storagePrimaryUri}
                                    onChange={(e) => {
                                        setNewStoragePrimaryUri(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_STORAGE_PRIMARY_URI_HTEXT}
                                />
                            </Stack>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_STORAGE_CONTAINER_NAME}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={externalConnections?.storageContainerName}
                                    onChange={(e) => {
                                        setNewStorageContainerName(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_STORAGE_CONTAINER_NAME_HTEXT}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.primary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* EMAIL SMTP */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.EXTCON_TITLE_EMAIL_SMTP}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_SSL}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={externalConnections?.emailSmtpEnableSsl} onChange={(e) => setNewEmailSMTPSSL(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_SSL_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_USENAME}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={externalConnections?.emailSmtpUsename}
                                    onChange={(e) => setNewEmailSMTPUseName(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_USENAME_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_HOST}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={externalConnections?.emailSmtpHost}
                                    onChange={(e) => setNewEmailSMTPHost(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_HOST_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PORT}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={externalConnections?.emailSmtpPort}
                                    onChange={(e) => setNewEmailSMTPPort(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PORT_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PASSWORD}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={showSmtpPassword ? "text" : "password"}
                                    defaultValue={externalConnections?.emailSmtpPassword}
                                    onChange={(e) => {
                                        setNewEmailSMTPPassword(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PASSWORD_HTEXT}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowSmtpPassword(!showSmtpPassword)}
                                                edge="end"
                                            >
                                                {showSmtpPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PASSWORD_SENDGRID}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    type={showSmtpPasswordSendGrid ? "text" : "password"}
                                    defaultValue={externalConnections?.emailSmtpPasswordSendGrid}
                                    onChange={(e) => {
                                        setEmailSmtpPasswordSendGrid(e.target.value)
                                    }}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.EXTCON_EMAIL_SMTP_PASSWORD_SENDGRID_HTEXT}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowSmtpPasswordSendGrid(!showSmtpPasswordSendGrid)}
                                                edge="end"
                                            >
                                                {showSmtpPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        } />
    );
}