import { Box, Button, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../app/AppStyles';
import { SearchKeyFilter } from '../../app/AppUtils';
import SearchField from '../subcomponents/SearchField';
import TablePaginationActions from '../subcomponents/TablePaginationActions';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ReportSelect({ content, contentId, contentDisplay, contentTitle, selectedContent, setSelectedContent, searchButton, handleClose }) {
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [filter, setFilter] = useState("");

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, [`${contentDisplay}`], filter);
    }

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < content?.filter(handleFilterResult).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === content?.filter(handleFilterResult).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = content?.filter(handleFilterResult).map((n) => n);
            setSelectedContent(newSelected);
            return;
        }
        setSelectedContent([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedContent.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedContent, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedContent.slice(1));
        } else if (selectedIndex === selectedContent.length - 1) {
            newSelected = newSelected.concat(selectedContent.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedContent.slice(0, selectedIndex),
                selectedContent.slice(selectedIndex + 1),
            );
        };

        setSelectedContent(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - content?.filter(handleFilterResult).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => selectedContent.findIndex(x => x[`${contentId}`] === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5, display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ ...PageHeader }}>{contentTitle}</Typography>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead numSelected={selectedContent?.length} onSelectAllClick={handleSelectAllClick} rowCount={selectedContent?.length} />
                                <TableBody>
                                    {(rowsPerPage > 0 ? content?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : content?.filter(handleFilterResult))?.map((item, index) => {
                                        const isItemSelected = isSelected(item[`${contentId}`]);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow hover onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                                </TableCell>
                                                <TableCell align="center">{item[`${contentDisplay}`]}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {(rowsPerPage > 0 ? content?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : content?.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            sx={{ border: 0 }}
                                            rowsPerPageOptions={[10, 25, 50,
                                                {
                                                    label: content?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                                    value: content?.filter(handleFilterResult).length < 100 ? -1 : 100
                                                }
                                            ]}
                                            count={content ? content.length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <Button variant="outlined" color="secondary" sx={{ width: 0.5 }} onClick={handleClose}>{searchButton ? resourceFinal[appLanguage].words.REPORT_GENERATE : resourceFinal[appLanguage].words.CLOSE}</Button>
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}
