import { Box, Card, CardActions, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import ContentLoader from '../subcomponents/ContentLoader';
import TravelDeclarationEditCard from './TravelDeclarationEditCard';
import { fetchDeclaration, selectDeclaration, setFailedDeclaration } from './travelCostSlice';

import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import ReplayIcon from '@mui/icons-material/Replay';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    maxHeight: '90%',
    maxWidth: '90%',
    boxShadow: 24,
};

export default function TravelDeclarationStatusCard({ declaration }) {
    var now = dayjs();
    const dispatch = useDispatch();

    const [openEdit, setOpenEdit] = useState(false);
    const [openResubmit, setOpenResubmit] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openHistoryImage, setOpenHistoryImage] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { selectedDeclaration, getDeclarationStatus } = useSelector((state) => state.travelCost);

    const handleEditClick = () => {
        dispatch(fetchDeclaration(declaration.id));
        setOpenEdit(!openEdit);
    };

    const handleResubmitClick = () => {
        dispatch(fetchDeclaration(declaration.id));
        setOpenResubmit(!openResubmit);
    };

    const handleHistoryClick = (id) => {
        dispatch(fetchDeclaration(id));
        setOpenHistory(!openHistory);
    };

    const callbackModalEdit = () => {
        setOpenEdit(false);
        setOpenResubmit(false);
        dispatch(setFailedDeclaration(false));
        dispatch(selectDeclaration(null));
    };

    return (
        <>
            <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', px: 2, pt: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ ...CardHeader }}>{declaration.performance.activityName} - {declaration.performance.performanceName ? declaration.performance.performanceName : declaration.performance.perforanceNumber}</Typography>
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.TIMEFRAME.toLowerCase()}: </Typography>
                        {`${GetDate(declaration.moment.starttime)} (${GetTime(declaration.moment.starttime)} - ${GetTime(declaration.moment.endtime)})`}
                    </Typography>
                    {declaration?.dateModified !== null ? (
                        <Tooltip title={`${resourceFinal[appLanguage].words.DATE} ${resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: ${GetDate(declaration?.dateCreated)} (${GetTime(declaration?.dateCreated)})`}>
                            <Typography variant="body1">
                                <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.EDITED.toLowerCase()}: </Typography>
                                {`${GetDate(declaration?.dateModified)} (${GetTime(declaration?.dateModified)})`}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography variant="body1">
                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: </Typography>
                            {`${GetDate(declaration?.dateCreated)} (${GetTime(declaration?.dateCreated)})`}
                        </Typography>
                    )}
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.COST}: </Typography>
                        {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(declaration.cost)}
                    </Typography>
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DISTANCE}: </Typography>
                        {declaration.distance}{resourceFinal[appLanguage].words.KILOMETRES}
                    </Typography>
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_STUDENTREMARK}: </Typography>
                        {declaration.studentRemark}
                    </Typography>
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.STATUS}: </Typography>
                        {declaration.approved === true ? resourceFinal[appLanguage].words.APPROVED : declaration.approved === false ? resourceFinal[appLanguage].words.DISAPPROVED : resourceFinal[appLanguage].messages.NOT_JUDGED} {declaration.hasResubmitted && "(" + resourceFinal[appLanguage].words.RESUBMITTED + ")"}
                    </Typography>
                    {declaration.approved !== null &&
                        <Typography variant="body1">
                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}: </Typography>
                            {declaration.remark ? declaration.remark : "-"}
                        </Typography>
                    }
                </Box>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
                    <Tooltip title={`${resourceFinal[appLanguage].words.EVIDENCE}${resourceFinal[appLanguage].words.IMAGE.toLowerCase()}`}>
                        <Box>
                            <IconButton onClick={() => setOpenImage(!openImage)}>
                                <ImageIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Tooltip title={resourceFinal[appLanguage].words.EDIT}>
                        <Box>
                            <IconButton color="secondary" onClick={handleEditClick} disabled={!(dayjs(declaration?.attendance?.dateCreated).add(siomSettings.travelCostHoursLimit, 'hour') > now) || declaration.approved !== null}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Tooltip title={resourceFinal[appLanguage].words.RESUBMIT}>
                        <Box>
                            <IconButton color="secondary" onClick={handleResubmitClick} disabled={!declaration.canResubmit}>
                                <ReplayIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    {declaration.prevDeclared &&
                        <Tooltip title={`${resourceFinal[appLanguage].words.PREVIOUS} ${resourceFinal[appLanguage].words.DECLARATION.toLowerCase()}`}>
                            <Box>
                                <IconButton color="secondary" onClick={() => handleHistoryClick(declaration.prevDeclared)}>
                                    <HistoryIcon />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    }
                </CardActions>
            </Card>

            {/*EDIT CARD MODAL*/}
            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <TravelDeclarationEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            {/*RESUBMIT CARD MODAL*/}
            <Modal open={openResubmit} onClose={callbackModalEdit}>
                <>
                    <TravelDeclarationEditCard handleClose={callbackModalEdit} resubmit />
                </>
            </Modal>

            {/*IMAGE DISPLAY MODAL*/}
            <Modal open={openImage} onClose={() => setOpenImage(!openImage)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}
                        component="img"
                        src={declaration.evidenceImage} alt="evidence image"
                    />
                </Box>
            </Modal>

            {/*HISTORY DISPLAY MODAL*/}
            <Modal open={openHistory} onClose={() => setOpenHistory(!openHistory)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}>
                        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 1 }}>
                            <ContentLoader successCondition={getDeclarationStatus === "success"} errorCondition={getDeclarationStatus === "failed"} content={
                                <>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6" sx={{ ...CardHeader }}>{selectedDeclaration?.performance?.activityName} - {selectedDeclaration?.performance?.performanceName ? selectedDeclaration?.performance?.performanceName : selectedDeclaration?.performance?.perforanceNumber}</Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.TIMEFRAME.toLowerCase()}: </Typography>
                                            {`${GetDate(selectedDeclaration?.moment?.starttime)} (${GetTime(selectedDeclaration?.moment?.starttime)} - ${GetTime(selectedDeclaration?.moment?.endtime)})`}
                                        </Typography>
                                        {selectedDeclaration?.dateModified !== null ? (
                                            <Tooltip title={`${resourceFinal[appLanguage].words.DATE} ${resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: ${GetDate(selectedDeclaration?.dateCreated)} (${GetTime(selectedDeclaration?.dateCreated)})`}>
                                                <Typography variant="body1">
                                                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.EDITED.toLowerCase()}: </Typography>
                                                    {`${GetDate(selectedDeclaration?.dateModified)} (${GetTime(selectedDeclaration?.dateModified)})`}
                                                </Typography>
                                            </Tooltip>
                                        ) : (
                                            <Typography variant="body1">
                                                <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: </Typography>
                                                {`${GetDate(selectedDeclaration?.dateCreated)} (${GetTime(selectedDeclaration?.dateCreated)})`}
                                            </Typography>
                                        )}
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.COST}: </Typography>
                                            {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(selectedDeclaration?.cost)}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DISTANCE}: </Typography>
                                            {selectedDeclaration?.distance} {resourceFinal[appLanguage].words.KILOMETRES}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_STUDENTREMARK}: </Typography>
                                            {selectedDeclaration?.StudentRemark} 
                                        </Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.STATUS}: </Typography>
                                            {selectedDeclaration?.approved === true ? resourceFinal[appLanguage].words.APPROVED : selectedDeclaration?.approved === false ? resourceFinal[appLanguage].words.DISAPPROVED : resourceFinal[appLanguage].messages.NOT_JUDGED}
                                        </Typography>
                                        {selectedDeclaration?.approved !== null &&
                                            <Typography variant="body1">
                                                <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}: </Typography>
                                                {selectedDeclaration?.remark ? selectedDeclaration?.remark : "-"}
                                            </Typography>
                                        }
                                    </Box>
                                    <CardActions sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
                                        <IconButton onClick={() => setOpenHistoryImage(!openHistoryImage)}>
                                            <ImageIcon color="secondary" />
                                        </IconButton>
                                    </CardActions>
                                </>
                            } />
                        </Card>
                    </Box>
                </Box>
            </Modal>

            {/*HISTORY IMAGE DISPLAY MODAL*/}
            <Modal open={openHistoryImage} onClose={() => setOpenHistoryImage(!openHistoryImage)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}
                        component="img"
                        src={selectedDeclaration?.evidenceImage} alt="evidence image"
                    />
                </Box>
            </Modal>
        </>
    );
}
