import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardBgcolor, PageHeader, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter, GetDate, GetTime } from '../../../app/AppUtils';
import { getAllContacts } from '../../contact/contactSlice';
import { getLocations } from '../../management-activity/location/locationManagementSlice';
import IconDisplay from '../../subcomponents/IconDisplay';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import PlanningPerformanceEditCard from '../performances/PlanningPerformanceEditCard';
import { resetDefaultActivityValues, resetPlanningPerformanceStatus, selectPerformance, setFailedPerformance } from '../performances/planningPerformanceSlice';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function PlanningActivityAccordion({ title, content, amount }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { planningActivitiesStatus } = useSelector((state) => state.planning.planning);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        if (planningActivitiesStatus === "success") {
            const size = content.filter(handleFilterResult).length;
            setItemsLeft(size - amount);
        };
    }, [planningActivitiesStatus, amount, content, filter]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getAllContacts());
    }, [dispatch]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const performanceClick = (performanceNumber, performanceId) => {
        navigate(`/plannen/uitvoering/${performanceNumber}/${performanceId}`)
    };

    const newClick = (item) => {
        dispatch(selectPerformance({ activity: { id: item.id, name: item.name } }));
        setOpenNew(!openNew);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(resetPlanningPerformanceStatus());
        dispatch(resetDefaultActivityValues());
        dispatch(setFailedPerformance(false));
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["name"], filter);
    }

    const PerformancesFragment = ({ subcontent }) => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subcontent.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? subcontent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subcontent).map(item =>
                        <TableRow key={item.id} hover onClick={() => performanceClick(item.number, item.id)} sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}>
                            {(appSettings?.appVersion === 2 || siomSettings?.enablePerformanceName) && <TableCell align="center">{item.name ? item.name : "-"}</TableCell>}
                            <TableCell align="center">{item.number}</TableCell>
                            {appSettings?.appVersion === 1 && <TableCell align="center">{item.moments}</TableCell>}
                            <TableCell align="center">
                                {item.moments > 1 ?
                                    `${GetDate(item.activityMoments[0].starttime)} - ${GetDate(item.activityMoments[item.activityMoments.length - 1].starttime)} 
                                        (${GetTime(item.activityMoments[0].starttime)} - ${GetTime(item.activityMoments[0].endtime)})` :
                                    `${GetDate(item.activityMoments[0].starttime)} (${GetTime(item.activityMoments[0].starttime)} - ${GetTime(item.activityMoments[0].endtime)})`
                                }
                            </TableCell>
                            <TableCell align="center">{item.capacityCurrent}/{item.capacityLimit}</TableCell>
                            <TableCell align="center">{GetDate(item.registerableFrom)} {GetTime(item.registerableFrom)}</TableCell>
                            <TableCell align="center">{GetDate(item.registerableUntil)} {GetTime(item.registerableUntil)}</TableCell>
                            <TableCell align="center" padding="checkbox"><ArrowForwardIosIcon color="secondary" /></TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? subcontent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subcontent).length === 0 && <TableRow><TableCell colSpan={7}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={7} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: subcontent?.length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: subcontent?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={subcontent?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box sx={{ width: 200 }}>
                    <SearchField func={handleSearchChange} />
                </Box>
                <Typography variant="h6" sx={{ ...PageHeader }}>{title} {resourceFinal[appLanguage].words.DISPLAY.toLowerCase()}</Typography>
                <Box sx={{ width: 200 }}></Box>
            </Box>
            <Box sx={{ mb: 1 }}>
                {content.filter(handleFilterResult).slice((page - 1) * amount, page * amount).map((item) =>
                    <Accordion key={item.id} sx={{ bgcolor: CardBgcolor }}>
                        <AccordionSummary sx={{ display: 'flex' }}>
                            <Avatar sx={{ mr: 1, bgcolor: 'secondary.main', color: 'white.light' }}>
                                <IconDisplay component={item.iconName} />
                            </Avatar>
                            <Typography sx={{ flexGrow: 1, alignSelf: 'center' }}>
                                {item.name}
                            </Typography>
                            <Typography sx={{ alignSelf: 'center' }}>({item.performances.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="outlined" onClick={() => newClick(item)} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {(appSettings?.appVersion === 2 || siomSettings?.enablePerformanceName) && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>}
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                                            {appSettings?.appVersion === 1 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.MOMENTS}</TableCell>}
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.TIMEFRAME}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CAPACITY}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIBABLE_FROM}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIBABLE_UNTIL}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <PerformancesFragment subcontent={item.performances} />
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
            {content.length === 0 && <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.RESULT_EMPTY}</Typography>}
            {pages > 1 && <Pagination sx={{ mt: 'auto', alignSelf: 'center' }} count={pages} page={page} onChange={handleChange} defaultPage={1} color="secondary" />}

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <PlanningPerformanceEditCard handleClose={callbackModalNew} addNew waitForInput />
                </>
            </Modal>
        </Box >
    );
}
