import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchTravelCostMoments = createAsyncThunk(
    '/api/employeeReports/travelCost/moments/exported',
    async (exported, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/travelCost/moments/${exported}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchTravelCostReportDateSpan = createAsyncThunk(
    '/api/employeeReports/travelCostTimeSpan/startDate/endDate/exported',
    async ({ startDate, endDate, exported }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/travelCostTimeSpan/${startDate}/${endDate}/${exported}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchTravelCostReport = createAsyncThunk(
    '/api/employeeReports/travelCost/momentId',
    async (momentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/travelCost/${momentId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postTravelCostExport = createAsyncThunk(
    '/api/employeeReports/travelcost/export',
    async (exportObject, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/travelcost/export`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(exportObject)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportTravelCostsSlice = createSlice({
    name: 'reportTravelCosts',
    initialState: {
        travelCostMoments: null,
        travelCostMomentsStatus: null,
        travelCostReport: null,
        travelCostReportStatus: null,
        travelCostExportStatus: null,
    },
    reducers: {
        resetReportTravelCosts: (state) => {
            state.travelCostMoments = null;
            state.travelCostMomentsStatus = null;
            state.travelCostReport = null;
            state.travelCostReportStatus = null;
            state.reportPerformances = null;
        },

    },
    extraReducers: (builder) => {
        // Get moments for travel cost
        builder
            .addCase(fetchTravelCostMoments.pending, (state) => {
                state.travelCostMomentsStatus = 'loading';
            })
            .addCase(fetchTravelCostMoments.fulfilled, (state, action) => {
                state.travelCostMoments = action.payload;
                state.travelCostMomentsStatus = 'success';
            })
            .addCase(fetchTravelCostMoments.rejected, (state) => {
                state.travelCostMomentsStatus = 'failed';
            });

        // Get travel cost report
        builder
            .addCase(fetchTravelCostReport.pending, (state) => {
                state.travelCostReportStatus = 'loading';
            })
            .addCase(fetchTravelCostReport.fulfilled, (state, action) => {
                state.travelCostReport = action.payload;
                state.travelCostReportStatus = 'success';
            })
            .addCase(fetchTravelCostReport.rejected, (state) => {
                state.travelCostReportStatus = 'failed';
            });

        // Get travel cost report
        builder
            .addCase(fetchTravelCostReportDateSpan.pending, (state) => {
                state.travelCostReportStatus = 'loading';
            })
            .addCase(fetchTravelCostReportDateSpan.fulfilled, (state, action) => {
                state.travelCostReport = action.payload;
                state.travelCostReportStatus = 'success';
            })
            .addCase(fetchTravelCostReportDateSpan.rejected, (state) => {
                state.travelCostReportStatus = 'failed';
            });

        // Post travel cost export
        builder
            .addCase(postTravelCostExport.pending, (state) => {
                state.travelCostExportStatus = 'loading';
            })
            .addCase(postTravelCostExport.fulfilled, (state) => {
                state.travelCostExportStatus = 'success';
            })
            .addCase(postTravelCostExport.rejected, (state) => {
                state.travelCostExportStatus = 'failed';
            });
    }
});

export const { resetReportTravelCosts } = reportTravelCostsSlice.actions;

export default reportTravelCostsSlice.reducer;