import { alpha, Box, Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter, DisplayName, GetDate } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { fetchReportActivities, fetchReportPerformances, resetReportFilterValues } from '../reportFiltersSlice';
import ExportExcelWintersport from './ExportExcelWintersport';
import { fetchWintersportReport } from './reportWintersportSlice';

export default function ReportWintersportTab() {
    const dispatch = useDispatch();

    const [searching, setSearching] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState("");
    const [selectedPerformanceId, setSelectedPerformanceId] = useState("");
    const [performanceFilter, setPerformanceFilter] = useState("");
    const [activityFilter, setActivityFilter] = useState("");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { wintersportReport, wintersportReportStatus } = useSelector((state) => state.reports.wintersport);
    const { reportActivities, reportActivitiesStatus, reportPerformances, reportPerformancesStatus } = useSelector((state) => state.reports.reportFilters);

    useEffect(() => {
        dispatch(resetReportFilterValues());
        dispatch(fetchReportActivities());
    }, [dispatch]);

    useEffect(() => {
        if (selectedActivityId)
            dispatch(fetchReportPerformances({ activityId: selectedActivityId, key: "subscriptions" }));
    }, [dispatch, selectedActivityId]);

    useEffect(() => {
        if (wintersportReportStatus === "success" || wintersportReportStatus === "failed")
            setSearching(false);
    }, [wintersportReportStatus]);

    const handleSearchReportClick = () => {
        setSearching(true);
        dispatch(fetchWintersportReport(selectedPerformanceId));
    };

    const handlePerformanceSearchChange = (e) => {
        if (selectedPerformanceId)
            setSelectedPerformanceId("");

        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setPerformanceFilter(value);
        }, 600);
    };

    const handleActivitySearchChange = (e) => {
        if (selectedActivityId)
            setSelectedActivityId("");

        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setActivityFilter(value);
        }, 600);
    };

    const handlePerformanceFilterResult = (item) => {
        return SearchKeyFilter(item, ["performanceNumber", "activityName"], performanceFilter);
    }

    const handleActivityFilterResult = (item) => {
        return SearchKeyFilter(item, ["activityName"], activityFilter);
    }

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(-1);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - wintersportReport?.students?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? wintersportReport?.students?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : wintersportReport?.students)?.map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{DisplayName(item.studentFirstName, item.studentInsertion, item.studentLastName)}</TableCell>
                            <TableCell align="center">{GetDate(item.studentDateOfBirth)}</TableCell>
                            <TableCell align="center">{item.studentSport}</TableCell>
                            <TableCell align="center">{item.studentHeight}</TableCell>
                            <TableCell align="center">{item.studentWeight}</TableCell>
                            <TableCell align="center">{item.studentShoeSize}</TableCell>
                            <TableCell align="center">{item.studentExperience}</TableCell>
                            <TableCell align="center">{item.studentMaterial}</TableCell>
                            <TableCell align="center">{item.studentGender}</TableCell>
                            <TableCell align="center">{item.studentMedication}</TableCell>
                            <TableCell align="center">{item.studentEmail}</TableCell>
                            <TableCell align="center">{item.studentPhone}</TableCell>
                            <TableCell align="center">{item.studentAllergy}</TableCell>
                            <TableCell align="center">{item.emergencyName}</TableCell>
                            <TableCell align="center">{item.emergencyPhone}</TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? wintersportReport?.students?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : wintersportReport?.students)?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: wintersportReport?.length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: wintersportReport?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={wintersportReport?.students ? wintersportReport?.students?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                <Box sx={{ display: 'flex', bgcolor: theme => alpha(theme.palette.white.main, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                    <Box sx={{ display: 'flex', mr: 1 }}>
                        <FormControl disabled={reportActivitiesStatus !== "success"}>
                            <InputLabel>{resourceFinal[appLanguage].words.ACTIVITY}</InputLabel>
                            <Select
                                onClose={() => setActivityFilter("")} sx={{ width: 240 }}
                                label={resourceFinal[appLanguage].words.ACTIVITY}
                                value={selectedActivityId}
                                onChange={(event) => {
                                    setSelectedPerformanceId("");
                                    setSelectedActivityId(event.target.value)
                                }}>
                                <MenuItem
                                    disableRipple
                                    dense
                                    divider
                                    value={""}
                                    onClickCapture={stopImmediatePropagation}
                                    onKeyDown={e => e.stopPropagation()}
                                >
                                    <SearchField func={handleActivitySearchChange} />
                                </MenuItem>
                                {reportActivities?.filter(handleActivityFilterResult)?.map((item) =>
                                    <MenuItem key={item.activityId} value={item.activityId}>{item.activityName} ({item.performancesSubscriptionCount})</MenuItem>
                                ) ?? []}
                            </Select>
                        </FormControl>
                        <FormControl disabled={reportPerformancesStatus !== "success"} sx={{ ml: 1 }}>
                            <InputLabel>{resourceFinal[appLanguage].words.PERFORMANCE}</InputLabel>
                            <Select
                                onClose={() => setPerformanceFilter("")}
                                sx={{ width: 240 }} label={resourceFinal[appLanguage].words.PERFORMANCE}
                                value={selectedPerformanceId}
                                onChange={(event) => setSelectedPerformanceId(event.target.value)}>
                                <MenuItem
                                    disableRipple
                                    dense
                                    divider
                                    value={""}
                                    onClickCapture={stopImmediatePropagation}
                                    onKeyDown={e => e.stopPropagation()}
                                >
                                    <SearchField func={handlePerformanceSearchChange} />
                                </MenuItem>
                                {reportPerformances?.filter(handlePerformanceFilterResult)?.map((item) =>
                                    <MenuItem key={item.performanceId} value={item.performanceId}>{item.performanceName}</MenuItem>
                                ) ?? []}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="secondary" disabled={!selectedPerformanceId} sx={{ ml: 1 }} onClick={handleSearchReportClick}>{resourceFinal[appLanguage].words.REPORT_GENERATE}</Button>
                    </Box>
                    <ExportExcelWintersport disabled={searching || !wintersportReport} jsonData={wintersportReport} />
                </Box>
                <ContentLoader successCondition={searching === false} errorCondition={wintersportReportStatus === "failed"} content={
                    <TableContainer>
                        <Table size="small" id="#report">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_SPORT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_HEIGHT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_WEIGHT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_SHOE_SIZE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_GENDER}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_MEDICATION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_ALLERGY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NAME}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NUMBER}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                } />
            </Box>
        </>
    );
}
