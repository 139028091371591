import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

export default function ExportExcelProgress({ disabled, jsonData, fileName }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [alteredData, setAlteredData] = useState([]);

    useEffect(() => {
        if (jsonData) {
            let updatedData;

            updatedData = jsonData?.map(({ studentId, ...rest }) => rest);

            updatedData = updatedData.map(item => {
                if (item.hasOwnProperty('requiredProgramStatus')) {
                    switch (item.requiredProgramStatus) {
                        case "green":
                            item.requiredProgramStatus = "Afgerond";
                            break;
                        case "yellow":
                            item.requiredProgramStatus = "Bezig";
                            break;
                        case "red":
                            item.requiredProgramStatus = "Niet begonnen";
                            break;
                        case "":
                        case null:
                            item.requiredProgramStatus = "Niet van toepassing";
                            break;
                        default:
                            break;
                    }
                }

                if (item.hasOwnProperty('lifestyleStatus')) {
                    switch (item.lifestyleStatus) {
                        case 2:
                            item.lifestyleStatus = "Afgerond";
                            break;
                        case 1:
                            item.lifestyleStatus = "Bezig";
                            break;
                        case 0:
                        case "":
                        case null:
                            item.lifestyleStatus = "Niet begonnen";
                            break;
                        default:
                            break;
                    }
                }

                return item;
            });

            setAlteredData(updatedData);
        }
    }, [jsonData]);

    const handleExportToExcel = () => {
        let sheetData = [];

        const headerRow = [
            `${resourceFinal[appLanguage].words.STUDENT}${resourceFinal[appLanguage].words.NUMBER.toLowerCase()}`,
            resourceFinal[appLanguage].words.NAME_FIRST,
            resourceFinal[appLanguage].words.NAME_INSERTION,
            resourceFinal[appLanguage].words.NAME_LAST,
            resourceFinal[appLanguage].words.CLASS,
            `${resourceFinal[appLanguage].words.POINTS} ${resourceFinal[appLanguage].words.POINTS_ACHIEVED.toLowerCase()}`,
            resourceFinal[appLanguage].words.REQUIRED_PROGRAM,
            resourceFinal[appLanguage].words.FLAGS_TJL,
        ];

        sheetData.push(headerRow);

        alteredData.forEach(item => {
            sheetData.push(Object.values(item));
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => handleExportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
