import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchNavSettings = createAsyncThunk(
    '/api/settings/navsettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/settings/navsettings', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const resourceSettingsSlice = createSlice({
    name: 'resourceSettings',
    initialState: {
        navigation: {
            pages: [],
            navigation: [],
            tiles: [],
            tileButtons: [],
            navSettingsStatus: null,
        },
        resources: {
            appLanguage: "nl",
            resourceDefaults: null,
            resourceOverrides: null,
            resourceFinal: {},
            resourceStatus: null,
        }
    },
    reducers: {
        getResources: (state) => { // Create resourceFinal from resources and overrides
            state.resources.resourceDefaults = window.Resources.Resources;
            state.resources.resourceOverrides = window.CustomerResources.ResourcesOverrides;

            const defaultResources = state.resources.resourceDefaults;
            for (var language in defaultResources) {
                const languageKey = language;

                if (!state.resources.resourceFinal.hasOwnProperty(languageKey))
                    state.resources.resourceFinal[languageKey] = {};

                for (var type in defaultResources[language]) {
                    const typeKey = type;

                    if (!state.resources.resourceFinal[language].hasOwnProperty(typeKey))
                        state.resources.resourceFinal[language][typeKey] = {};

                    for (var property in defaultResources[language][type]) {
                        const propertyKey = property;

                        if (!state.resources.resourceFinal[language][type].hasOwnProperty(propertyKey))
                            state.resources.resourceFinal[language][type][propertyKey] = state.resources.resourceOverrides[language].hasOwnProperty(type) ? (state.resources.resourceOverrides[language][type].hasOwnProperty(propertyKey) ?
                                state.resources.resourceOverrides[language][type][propertyKey] : defaultResources[language][type][propertyKey]) : defaultResources[language][type][propertyKey];
                    };
                };
            };

            state.resources.resourceStatus = "success";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNavSettings.pending, (state) => {
                state.navSettingsStatus = 'loading'
            })
            .addCase(fetchNavSettings.fulfilled, (state, action) => {
                const object = action.payload

                if (state.resources.resourceStatus === "success") {
                    // Function to replace nav item names from DB with resources (ADD TRANSLATION WHEN ADDING NEW NAV ITEMS)
                    function useResources(array) {
                        const resourceMap = {
                            "Agenda": "SCHEDULE",
                            "Inschrijven": "REGISTER",
                            "Voortgang": "PROGRESS",
                            "Contact": "CONTACT",
                            "Favorieten": "FAVORITES",
                            "Informatie": "INFORMATION",
                            "Historie": "HISTORY",
                            "Leaderboard": "LEADERBOARD",
                            "Rooster": "TIMETABLE",
                            "Activiteitenbeheer": "ACTIVITY_MANAGEMENT",
                            "Programmabeheer": "PROGRAM_MANAGEMENT",
                            "Plannen": "PLANNING",
                            "Webbeheer": "WEB_MANAGEMENT",
                            "Rapporten": "REPORTS_PAGE",
                            "Presentie": "ATTENDANCE",
                            "Zoeken": "SEARCH_PAGE",
                            "Berichten": "MESSAGES_PAGE",
                            "Medewerkers": "EMPLOYEE_PAGE",
                            "Admin": "ADMIN_PAGE",
                            "Nieuws": "NEWS",
                            "Reiskosten": "TRAVEL_COSTS"
                        };

                        return array.map(item => {
                            const resourceKey = resourceMap[item];
                            return resourceKey ? state.resources.resourceFinal[state.resources.appLanguage].words[resourceKey] : "";
                        });
                    }

                    // Create empty temporary arrays
                    var pagesTemp = [];
                    var navigationTemp = [];
                    var tilesTemp = [];
                    var tileButtonsTemp = [];

                    // Add 'Home' nav item to front of array
                    pagesTemp.push(state.resources.resourceFinal[state.resources.appLanguage].words.HOME);
                    navigationTemp.push(state.resources.resourceFinal[state.resources.appLanguage].words.HOME);

                    // Fill temp arrays with (transformed) nav items from DB
                    pagesTemp = pagesTemp.concat(object.pages);
                    navigationTemp = navigationTemp.concat(useResources(object.navigation));
                    tilesTemp = tilesTemp.concat(object.tiles);
                    tileButtonsTemp = tileButtonsTemp.concat(useResources(object.tileButtons));

                    // Add 'About' nav item to end of array
                    pagesTemp.push(state.resources.resourceFinal[state.resources.appLanguage].words.ABOUT);

                    // Fill state with items in temp arrays
                    state.navigation.pages = pagesTemp;
                    state.navigation.navigation = navigationTemp;
                    state.navigation.tiles = tilesTemp;
                    state.navigation.tileButtons = tileButtonsTemp;

                    // Set nav settings status to success
                    state.navSettingsStatus = 'success';
                };
            })
            .addCase(fetchNavSettings.rejected, (state) => {
                state.navSettingsStatus = 'failed';
            });
    },
});

export const { getResources } = resourceSettingsSlice.actions;

export default resourceSettingsSlice.reducer;