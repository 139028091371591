import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../app/AppStyles';
import { putEmployee, resetEmployeeStatus } from './employeeSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: 'auto' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function EmployeeEditCard({ selectedEmployee, handleClose, addNew }) {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const { putEmployeeStatus } = useSelector((state) => state.employees);

    // Put status callback
    useEffect(() => {
        if (putEmployeeStatus === "success") {
            dispatch(resetEmployeeStatus());
            dispatch(setAlertStatus(true))
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SAVE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            handleClose();
        } else if (putEmployeeStatus === "failed") {
            dispatch(setAlertStatus(true))
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}`));
            dispatch(setAlertSeverity("error"));

        };
    }, [dispatch, handleClose, putEmployeeStatus]);


    // Property states
    const [newEmployee, setNewEmployee] = useState({});

    const [changed, setChanged] = useState(false);

    // Selected program state update 
    useEffect(() => {
        if (selectedEmployee)
            setNewEmployee(selectedEmployee);
    }, [selectedEmployee]);

    // Keep track of changed state 
    useEffect(() => {
        if (newEmployee !== selectedEmployee) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedEmployee, newEmployee]);



    // Update click function (check property character limits etc.)
    const updateClick = () => {
        newEmployee.ModifiedBy = `AP/${user.number}`;
        dispatch(putEmployee(newEmployee));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.EMPLOYEE_ROLES} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.ROLE_ACTIVITYMASTER} `}
                                control={<Checkbox
                                    checked={newEmployee?.activityMaster ? newEmployee?.activityMaster : false}
                                    onChange={(event) => setNewEmployee({
                                        ...newEmployee,
                                        activityMaster: event.target.checked ? true : false
                                    })}
                                />}
                            />
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.ROLE_PLANNER} `}
                                control={<Checkbox
                                    checked={newEmployee?.planner ? newEmployee?.planner: false}
                                    onChange={(event) => setNewEmployee({
                                        ...newEmployee,
                                        planner: event.target.checked ? true : false
                                    })}
                                />}
                            />
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.ROLE_REPORTER} `}
                                control={<Checkbox
                                    checked={newEmployee?.reporter ? newEmployee?.reporter : false}
                                    onChange={(event) => setNewEmployee({
                                        ...newEmployee,
                                        reporter: event.target.checked ? true : false
                                    })}
                                />}
                            />
                        
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.ROLE_EMPLOYEEMANAGER} `}
                                control={<Checkbox
                                    checked={newEmployee?.employeeManager ? newEmployee?.employeeManager : false}
                                    onChange={(event) => setNewEmployee({
                                        ...newEmployee,
                                        employeeManager: event.target.checked ? true : false
                                    })}
                                />}
                            />
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.ROLE_WEBMASTER} `}
                                control={<Checkbox
                                    checked={newEmployee?.webmaster ? newEmployee?.webmaster : false}
                                    onChange={(event) => setNewEmployee({
                                        ...newEmployee,
                                        webmaster: event.target.checked ? true : false
                                    })}
                                />}
                            />
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                            </ButtonGroup>
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}