import { Avatar, Box, Checkbox, FormControlLabel, FormGroup, IconButton, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { DisplayName } from '../../../app/AppUtils';
import { setAllPeriodChecks, setCheckedPeriod } from '../../register/registerSlice';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import ErrorDisplay from '../../subcomponents/ErrorDisplay';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchSearchStudent, setStudentRegisterFilter, setStudentRegisterPage, setStudentRegisterParentCheck, setStudentRegisterReasonMode } from './searchStudentSlice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Tabs
import SearchStudentAttendanceTab from './tabs/attendance/SearchStudentAttendanceTab';
import SearchStudentPerformanceTab from './tabs/register/SearchStudentPerformanceTab';
import SearchStudentRegisterTab from './tabs/register/SearchStudentRegisterTab';
import SearchStudentSummaryTab from './tabs/register/SearchStudentSummaryTab';
import SearchStudentInfoTab from './tabs/SearchStudentInfoTab';
import SearchStudentProgressTab from './tabs/SearchStudentProgressTab';
import SearchStudentTimetableTab from './tabs/SearchStudentTimetableTab';
import SearchStudentProgramTab from './tabs/SearchStudentProgramTab';
import SearchStudentScheduleTab from './tabs/SearchStudentScheduleTab';
import SearchStudentHistoryTab from './tabs/SearchStudentHistoryTab';

function CustomTabPanel({ children, value, index, ...other }) {

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 1, borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SearchStudentResultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterPage, studentRegisterReasonMode, studentRegisterParentCheck } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { deleteSubscriptionStatus } = useSelector((state) => state.subscription);
    const { user } = useSelector((state) => state.user);
    const { registerStatus, registerAllStatus, periods, subscriptionStatus } = useSelector((state) => state.register);

    const [tabValue, setTabValue] = useState(0);
    const [registerTabEnabled, setRegisterTabEnabled] = useState(false);

    // Set register enabled state
    useEffect(() => {
        setRegisterTabEnabled(siomSettings?.showSearchRegisterTab && !user?.onlyEmployee);
    }, [siomSettings, user]);

    // Get student data (progress & info)
    useEffect(() => {
        var departmentId = (siomSettings.useMultipleDepartments && activeDepartment) ? activeDepartment[0]?.id : null

        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading") {
            if (!siomSettings.useMultipleDepartments) {
                dispatch(fetchSearchStudent({ studentId: id, departmentId: null }));
            }

            if (siomSettings.useMultipleDepartments && activeDepartment) {
                dispatch(fetchSearchStudent({ studentId: id, departmentId: departmentId }));
            }
        };
    }, [dispatch, id, deleteSubscriptionStatus, subscriptionStatus, activeDepartment, siomSettings]);

    const tabsList = [
        {
            label: resourceFinal[appLanguage].words.PROGRESS,
            component: <SearchStudentProgressTab />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.REGISTER,
            component:
                studentRegisterPage === 'REGISTER' ? <SearchStudentRegisterTab studentId={id} /> :
                    studentRegisterPage === 'PERFORMANCE' ? <SearchStudentPerformanceTab studentId={id} /> :
                        studentRegisterPage === 'SUMMARY' ? <SearchStudentSummaryTab studentId={id} /> :
                            <ErrorDisplay />,
            condition: registerTabEnabled,
        },
        {
            label: resourceFinal[appLanguage].words.SCHEDULE,
            component: <SearchStudentScheduleTab studentId={id} />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.TIMETABLE,
            component: <SearchStudentTimetableTab studentId={id} />,
            condition: siomSettings.mollieActive,
        },
        {
            label: resourceFinal[appLanguage].words.ATTENDANCE,
            component: <SearchStudentAttendanceTab studentId={id} />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.PROGRAM,
            component: <SearchStudentProgramTab studentId={id} />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.STUDENT_DATA,
            component: <SearchStudentInfoTab />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.HISTORY,
            component: <SearchStudentHistoryTab studentId={id} />,
            condition: true,
        },
    ]

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const FilterFragment = () => {
        return (
            <Box>
                <ToggleButtonGroup
                    color="primary"
                    disabled={registerStatus === "loading" || registerAllStatus === "loading"}
                    fullWidth
                    exclusive
                    value={studentRegisterReasonMode ? "EMPLOYEE" : "STUDENT"}
                    onChange={(value) => dispatch(setStudentRegisterReasonMode(value === "EMPLOYEE" ? true : false))}
                >
                    {siomSettings?.showSearchRegisterTab && <ToggleButton value="STUDENT">{resourceFinal[appLanguage].words.STUDENT}</ToggleButton>}
                    <ToggleButton value="EMPLOYEE">{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}</ToggleButton>
                </ToggleButtonGroup>
                {((registerStatus === "success" || registerAllStatus === "success") && (registerStatus !== "loading" && registerAllStatus !== "loading")) &&
                    <>
                        {periods?.length > 0 && (
                            <>
                                <FormControlLabel
                                    label={resourceFinal[appLanguage].words.PERIODS}
                                    control={
                                        <Checkbox
                                            checked={studentRegisterParentCheck}
                                            onChange={
                                                () => {
                                                    dispatch(setStudentRegisterParentCheck())
                                                    periods?.forEach((item) => {
                                                        dispatch(setAllPeriodChecks({ id: item.periodId, bool: !studentRegisterParentCheck }));
                                                    });
                                                }
                                            }
                                        />
                                    } />
                                <FormGroup sx={{ width: 1, alignSelf: 'center' }}>
                                    {periods?.map((item) => <PeriodCheckbox key={item.periodId} item={item} />)}
                                </FormGroup>
                            </>
                        )}
                    </>
                }
            </Box>
        );
    };

    const PeriodCheckbox = ({ item }) => {
        return (
            <FormControlLabel
                sx={{ alignSelf: 'left', ml: 2 }}
                label={item.name}
                control={
                    <Checkbox
                        checked={item.checked}
                        onChange={() => {
                            dispatch(setCheckedPeriod(item.periodId))
                        }}
                    />
                }
            />
        );
    };

    const handleRegisterBackClick = () => {
        if (studentRegisterPage === 'REGISTER')
            navigate(-1);
        else
            dispatch(setStudentRegisterPage("REGISTER"));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dispatch(setStudentRegisterFilter(value));
        }, 600);
    };

    return (
        <PageLayout title={searchStudentStatus === "success" ? `${DisplayName(searchStudent.studentData?.firstName, searchStudent.studentData?.middleName, searchStudent.studentData?.lastName)} (${searchStudent.studentData?.number} - ${searchStudent.studentData?.extraInfo?.class ?? ""})` : `${resourceFinal[appLanguage].words.STUDENT}`} content={
            <ContentLoader successCondition={searchStudentStatus === "success"} errorCondition={searchStudentStatus === "failed"} content={
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} centered>
                            {tabsList
                                .filter(tab => tab.condition)
                                .map((tab, index) => (
                                    <Tab key={index} label={tab.label} {...a11yProps(index)} />
                                ))}
                        </Tabs>
                    </Box>
                    {tabsList
                        .filter(tab => tab.condition)
                        .map((tab, index) => (
                            <CustomTabPanel key={index} value={tabValue} index={index}>
                                {tab.component}
                            </CustomTabPanel>
                        ))}
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                {(tabValue !== 1 || !registerTabEnabled) && <BackButton desktop />}
                {(tabValue === 1 && registerTabEnabled) &&
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={handleRegisterBackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        } contentLeft={
            (tabValue === 1 && registerTabEnabled) && <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar />
    );
}