import { Box, Card, CardActions, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import ConfirmationModal from '../subcomponents/ConfirmationModal';
import ContentLoader from '../subcomponents/ContentLoader';
import { declarationJudgment, fetchDeclaration } from './travelCostSlice';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    maxHeight: '90%',
    maxWidth: '90%',
    boxShadow: 24,
};

export default function TravelDeclarationEmployeeCard({ declaration }) {
    const dispatch = useDispatch();

    const [openImage, setOpenImage] = useState(false);
    const [remark, setRemark] = useState("");
    const [openHistory, setOpenHistory] = useState(false);
    const [openHistoryImage, setOpenHistoryImage] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedDeclaration, getDeclarationStatus } = useSelector((state) => state.travelCost);
    const { user } = useSelector((state) => state.user);

    const handleJudgeClick = (judgement) => {
        dispatch(declarationJudgment({
            declarationId: declaration.id,
            approved: judgement,
            approvedBy: `${user.number}`,
            remark: remark
        }));
    };

    const handleHistoryClick = (id) => {
        dispatch(fetchDeclaration(id));
        setOpenHistory(!openHistory);
    };

    return (
        <>
            <Card variant="outlined" sx={{ p: 1 }}>
                <Typography variant="h6" sx={{ ...CardHeader }}>{declaration.performance.activityName} - {declaration.performance.performanceName ? declaration.performance.performanceName : declaration.performance.perforanceNumber}</Typography>
                <Typography variant="subtitle1" sx={{ ...CardHeader }}>{declaration.student.fullname} ({declaration.student.studentNumber})</Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.TIMEFRAME.toLowerCase()}: </Typography>
                    {`${GetDate(declaration.moment.starttime)} (${GetTime(declaration.moment.starttime)} - ${GetTime(declaration.moment.endtime)})`}
                </Typography>
                {declaration?.dateModified !== null ? (
                    <Tooltip title={`${resourceFinal[appLanguage].words.DATE} ${resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: ${GetDate(declaration?.dateCreated)} (${GetTime(declaration?.dateCreated)})`}>
                        <Typography variant="body1">
                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.EDITED.toLowerCase()}: </Typography>
                            {`${GetDate(declaration?.dateModified)} (${GetTime(declaration?.dateModified)})`}
                        </Typography>
                    </Tooltip>
                ) : (
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: </Typography>
                        {`${GetDate(declaration?.dateCreated)} (${GetTime(declaration?.dateCreated)})`}
                    </Typography>
                )}
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.COST}: </Typography>
                    {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(declaration.cost)}
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DISTANCE}: </Typography>
                    {declaration.distance}{resourceFinal[appLanguage].words.KILOMETRES}
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_STUDENTREMARK}: </Typography>
                    {declaration.studentRemark}
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.STUDENT} {resourceFinal[appLanguage].words.USER_DATA_CITY.toLowerCase()}: </Typography>
                    {declaration.studentCity}
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.ACTIVITY} {resourceFinal[appLanguage].words.ADDRESS.toLowerCase()}: </Typography>
                    {`${declaration.performance.location.street}${declaration.performance.location.streetNumber ? " " + declaration.performance.location.streetNumber : ""}, ${declaration.performance.location.city}`}
                </Typography>
                {declaration.approved !== null &&
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}: </Typography>
                        {declaration.approvedBy !== null ? declaration.approvedBy : "-"}
                    </Typography>
                }
                {declaration.approved !== null &&
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.STATUS}: </Typography>
                        {declaration.approved === true ? resourceFinal[appLanguage].words.APPROVED : declaration.approved === false ? resourceFinal[appLanguage].words.DISAPPROVED : "-"}
                    </Typography>
                }
                {declaration.approved !== null &&
                    <Typography variant="body1">
                        <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}: </Typography>
                        {declaration.remark}
                    </Typography>
                }
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title={`${resourceFinal[appLanguage].words.EVIDENCE}${resourceFinal[appLanguage].words.IMAGE.toLowerCase()}`}>
                        <Box>
                            <IconButton onClick={() => setOpenImage(!openImage)}>
                                <ImageIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    {declaration.approved === null &&
                        <>
                            <Tooltip title={`${resourceFinal[appLanguage].words.DISAPPROVE}`}>
                                <Box>
                                    <ConfirmationModal iconButton={true} icon={<CloseIcon color="error" />} customField={
                                        <TextField
                                            fullWidth
                                        label={resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}
                                            onChange={(e) => setRemark(e.target.value)}
                                            variant="outlined"
                                        />
                                    } message={resourceFinal[appLanguage].messages.TRAVEL_COST_DISAPPROVAL} confirmAction={() => handleJudgeClick(false)} />
                                </Box>
                            </Tooltip>
                            <Tooltip title={`${resourceFinal[appLanguage].words.APPROVE}`}>
                                <Box>
                                    <ConfirmationModal iconButton={true} icon={<CheckIcon color="success" />} customField={
                                        <TextField
                                            fullWidth
                                            label={resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}
                                            onChange={(e) => setRemark(e.target.value)}
                                            variant="outlined"
                                        />
                                    } message={resourceFinal[appLanguage].messages.TRAVEL_COST_APPROVAL} confirmAction={() => handleJudgeClick(true)} />
                                </Box>
                            </Tooltip>
                        </>
                    }
                    {declaration.prevDeclared &&
                        <Tooltip title={`${resourceFinal[appLanguage].words.PREVIOUS} ${resourceFinal[appLanguage].words.DECLARATION.toLowerCase()}`}>
                            <Box>
                                <IconButton color="secondary" onClick={() => handleHistoryClick(declaration.prevDeclared)}>
                                    <HistoryIcon />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    }
                </CardActions>
            </Card>

            {/*IMAGE DISPLAY MODAL*/}
            <Modal open={openImage} onClose={() => setOpenImage(!openImage)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}
                        component="img"
                        src={declaration.evidenceImage} alt="evidence image"
                    />
                </Box>
            </Modal>

            {/*HISTORY DISPLAY MODAL*/}
            <Modal open={openHistory} onClose={() => setOpenHistory(!openHistory)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}>
                        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 1 }}>
                            <ContentLoader successCondition={getDeclarationStatus === "success"} errorCondition={getDeclarationStatus === "failed"} content={
                                <>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6" sx={{ ...CardHeader }}>{selectedDeclaration?.performance?.activityName} - {selectedDeclaration?.performance?.performanceName ? selectedDeclaration?.performance?.performanceName : selectedDeclaration?.performance.perforanceNumber}</Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.TIMEFRAME.toLowerCase()}: </Typography>
                                            {`${GetDate(selectedDeclaration?.moment?.starttime)} (${GetTime(selectedDeclaration?.moment?.starttime)} - ${GetTime(selectedDeclaration?.moment?.endtime)})`}
                                        </Typography>
                                        {selectedDeclaration?.dateModified !== null ? (
                                            <Tooltip title={`${resourceFinal[appLanguage].words.DATE} ${resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: ${GetDate(selectedDeclaration?.dateCreated)} (${GetTime(selectedDeclaration?.dateCreated)})`}>
                                                <Typography variant="body1">
                                                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.EDITED.toLowerCase()}: </Typography>
                                                    {`${GetDate(selectedDeclaration?.dateModified)} (${GetTime(selectedDeclaration?.dateModified)})`}
                                                </Typography>
                                            </Tooltip>
                                        ) : (
                                            <Typography variant="body1">
                                                <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DATE} {resourceFinal[appLanguage].words.SUBMITTED.toLowerCase()}: </Typography>
                                                {`${GetDate(selectedDeclaration?.dateCreated)} (${GetTime(selectedDeclaration?.dateCreated)})`}
                                            </Typography>
                                        )}
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.COST}: </Typography>
                                            {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(selectedDeclaration?.cost)}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.DISTANCE}: </Typography>
                                            {selectedDeclaration?.distance} {resourceFinal[appLanguage].words.KILOMETRES}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.STATUS}: </Typography>
                                            {selectedDeclaration?.approved === true ? resourceFinal[appLanguage].words.APPROVED : selectedDeclaration?.approved === false ? resourceFinal[appLanguage].words.DISAPPROVED : resourceFinal[appLanguage].messages.NOT_JUDGED}
                                        </Typography>
                                        {selectedDeclaration?.approved !== null &&
                                            <Typography variant="body1">
                                                <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}: </Typography>
                                                {selectedDeclaration?.remark ? selectedDeclaration?.remark : "-"}
                                            </Typography>
                                        }
                                    </Box>
                                    <CardActions sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
                                        <IconButton onClick={() => setOpenHistoryImage(!openHistoryImage)}>
                                            <ImageIcon color="secondary" />
                                        </IconButton>
                                    </CardActions>
                                </>
                            } />
                        </Card>
                    </Box>
                </Box>
            </Modal>

            {/*HISTORY IMAGE DISPLAY MODAL*/}
            <Modal open={openHistoryImage} onClose={() => setOpenHistoryImage(!openHistoryImage)}>
                <Box sx={{ ...modalStyle }}>
                    <Box sx={{ maxWidth: 1, maxHeight: 1 }}
                        component="img"
                        src={selectedDeclaration?.evidenceImage} alt="evidence image"
                    />
                </Box>
            </Modal>
        </>
    );
}
