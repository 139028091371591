export const requestOptions = {
    headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    }),
    method: 'GET',
    redirect: 'follow',
};

export function AddZero(input) {
    return (input < 10 ? "0" : "") + input;
};

export function GetDate(input) {
    let date = new Date(input);
    let result = date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return result;
};

export function GetDateLong(input) {
    let date = new Date(input);
    let result = date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "short",
    });

    return result;
};

export function GetTime(input) {
    const date = new Date(input);

    return `${AddZero(date.getHours())}:${AddZero(date.getMinutes())}`;
};

export function GetDayName(input) {
    const date = new Date(input);
    let result = date.toLocaleDateString("nl-NL", { weekday: 'long' });

    return result;
};

export function GetMonthName(input) {
    const date = new Date(input);
    let result = date.toLocaleDateString("nl-NL", { month: 'long' });

    return result;
};

export function GetYear(input) {
    const date = new Date(input);
    let result = date.toLocaleDateString("nl-NL", { year: 'numeric' });

    return result;
};

export function FilterLoop(fn, a) {
    const f = [];

    for (let i = 0; i < a.length; i++) {
        if (fn(a[i])) {
            f.push(a[i]);
        }
    }

    return f;
};

export function DisplayName(first, ins, last) {
    return `${first} ${ins ? ins + " " + last : last}`;
};

export function HandleImageUpload(event) {
    let name;
    let file = event.target.files[0];
    name = file.name;

    return new Promise((resolve, reject) => {
        // Check file type
        if (!file.type.match('image.*')) {
            console.log("promise here")
            reject(new Error("TYPE"));
        };

        // Check file size
        console.log("File size:", `${(file.size / (1024 * 1024)).toFixed(2)}MB`)
        if ((file.size / (1024 * 1024)).toFixed(2) > 8) { // 8MB limit
            reject(new Error("SIZE"));
        };

        // Upload file
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            let img = new Image();
            img.src = reader.result;

            img.onload = function () {
                resolve({ image: img.src, name: name });
            };

            img.onerror = function (err) {
                reject(new Error(err));
            };
        };
    });
};

export function ConvertoToCSharpDateTime(input) {
    return `${AddZero(input.$y)}-${AddZero(Number(input.$M) + 1)}-${AddZero(input.$D)}T${AddZero(input.$H)}:${AddZero(input.$m)}:${AddZero(input.$s)}`
};

export function SearchKeyFilter(source, sourceKeys, target) {
    const resolveKey = (obj, key) => {
        return key.split('.').reduce((o, k) => (o || {})[k], obj);
    };

    let transSource = sourceKeys
        .map(key => String(resolveKey(source, key)) || "")
        .join(" ")
        .replace(/ /g, "")
        .toLowerCase();

    let transTarget = target.replace(/ /g, "").toLowerCase();

    return transSource.includes(transTarget);
};