import { Box, Checkbox, FormControlLabel, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, TableHeader } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import { fetchEmployees, selectEmployee } from './employeeSlice';

import EditIcon from '@mui/icons-material/Edit';
import { SearchKeyFilter } from '../../app/AppUtils';
import EmployeeEditCard from './EmployeeEditCard';

export default function EmployeePage() {
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { employees, employeesStatus } = useSelector((state) => state.employees);

    const [filter, setFilter] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [openEdit, setOpenEdit] = useState(false);

    const [selectedEmployee, setSelectedEmployee] = useState(null);

    useEffect(() => {
        dispatch(fetchEmployees(isActive));
    }, [dispatch, isActive]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };


    const callbackModalEdit = () => {
        setOpenEdit(false);
        setSelectedEmployee(null);
        dispatch(fetchEmployees(isActive));
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["fullname", "number"], filter);
    }

    const FilterFragment = () => {
        return (
            <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={isActive} onChange={() => setIsActive(!isActive)} />} label={`${resourceFinal[appLanguage].words.ACTIVE}`} />
        );
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employees?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleEditClick = (item) => {
            setOpenEdit(true);
            setSelectedEmployee(item);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? employees.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employees.filter(handleFilterResult)).map(item =>
                        <TableRow key={item.id} hover onClick={() => handleEditClick(item)} sx={{ cursor: 'pointer', '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">{item.number}</TableCell>
                            <TableCell align="left">{item.fullname}</TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.activityMaster} /></TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.planner} /></TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.reporter} /></TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.employeeManager} /></TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.webmaster} /></TableCell>
                            <TableCell align="center"><EditIcon color="secondary" /></TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? employees.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employees.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={12}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 63 * emptyRows }}>
                            <TableCell colSpan={12} />
                        </TableRow>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 25,
                                {
                                    label: employees?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: employees?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={employees?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (<>
        <PageLayout title={resourceFinal[appLanguage].words.EMPLOYEE_PAGE} content={
            <ContentLoader successCondition={employeesStatus === "success"} errorCondition={employeesStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader, width: 60 }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_ACTIVITYMASTER}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_PLANNER}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_REPORTER}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_EMPLOYEEMANAGER}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_WEBMASTER}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />
        } contentLeft={
            <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            <FilterButton filters={<FilterFragment />} />
        } />
        <Modal open={openEdit} onClose={callbackModalEdit}>
            <>
                <EmployeeEditCard selectedEmployee={selectedEmployee} handleClose={callbackModalEdit} />
            </>
        </Modal>
    </>

    );

}
