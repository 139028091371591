import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetDate, GetTime } from '../../../app/AppUtils';
import { ListItemBackground, TableHeader } from '../../../app/AppStyles';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchPlanningMoments } from './planningMomentsSlice';
//import EditIcon from '@mui/icons-material/Edit';

export default function PlanningMomentsPage() {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { planningMoments, planningMomentsStatus } = useSelector((state) => state.planning.moments);

    useEffect(() => {
        dispatch(fetchPlanningMoments(id));
    }, [dispatch, id]);

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planningMoments?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        //const attendanceClick = (item) => {
        //    dispatch(fetchMomentAttendance(item.momentId));
        //    navigate(`/presentie/moment/${item.momentId}`);
        //};

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? planningMoments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : planningMoments).map(item =>
                        <TableRow key={item.momentId} sx={{ cursor: 'pointer', '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">{GetDate(item.startTime)} ({GetTime(item.startTime)})</TableCell>
                            <TableCell align="center">{GetDate(item.endTime)} ({GetTime(item.endTime)})</TableCell>
                            <TableCell align="left">{item.activityName}</TableCell>
                            <TableCell align="center">{item.performanceNumber}</TableCell>
                            <TableCell align="center">{item.performanceName ? item.performanceName : "-"}</TableCell>
                            {/*<TableCell align="center"><EditIcon color="secondary" /></TableCell>*/}
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? planningMoments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : planningMoments).length === 0 && <TableRow><TableCell colSpan={6}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: planningMoments?.length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: planningMoments?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={planningMoments?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };


    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.MOMENTS}`} content={
            <ContentLoader successCondition={planningMomentsStatus === "success"} errorCondition={planningMomentsStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    {/*<TableCell align="center" padding="checkbox"></TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } />
    );
}
