import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchSchoolclassProgressReport = createAsyncThunk(
    '/api/employeeReports/progress',
    async ({ schoolclassIds, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/employeeReports/progress/${departmentId}` : `api/employeeReports/progress`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': "application/json",
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(schoolclassIds),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSchoolclassResultReport = createAsyncThunk(
    '/api/employeeReports/result',
    async ({ schoolclassIds, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/employeeReports/result/${departmentId}` : `api/employeeReports/result`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': "application/json",
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(schoolclassIds),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportProgressSlice = createSlice({
    name: 'reportProgress',
    initialState: {
        progressReport: null,
        progressReportStatus: null,
        resultReport: null,
        resultReportStatus: null,
    },
    reducers: {
        resetReportProgress: (state) => {
            state.progressReport = null;
            state.progressReportStatus = null;
            state.resultReport = null;
            state.resultReportStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get schoolclass progress report
        builder
            .addCase(fetchSchoolclassProgressReport.pending, (state) => {
                state.progressReportStatus = 'loading';
            })
            .addCase(fetchSchoolclassProgressReport.fulfilled, (state, action) => {
                state.progressReport = action.payload;
                state.progressReportStatus = 'success';
            })
            .addCase(fetchSchoolclassProgressReport.rejected, (state) => {
                state.progressReportStatus = 'failed';
            });

        // Get schoolclass result report
        builder
            .addCase(fetchSchoolclassResultReport.pending, (state) => {
                state.resultReportStatus = 'loading';
            })
            .addCase(fetchSchoolclassResultReport.fulfilled, (state, action) => {
                state.resultReport = action.payload;
                state.resultReportStatus = 'success';
            })
            .addCase(fetchSchoolclassResultReport.rejected, (state) => {
                state.resultReportStatus = 'failed';
            });
    }
});

export const { resetReportProgress } = reportProgressSlice.actions;

export default reportProgressSlice.reducer;