import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchIdealTransasctionsReport = createAsyncThunk(
    '/api/employeeReports/idealTransactions',
    async (performanceIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/idealTransactions`, {
            headers: new Headers({
                'Content-Type': "application/json",
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(performanceIds),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportIdealTransactionsSlice = createSlice({
    name: 'reportIdealTransactions',
    initialState: {
        idealTransactionsReport: null,
        idealTransactionsReportStatus: null,
    },
    reducers: {
        resetIdealTransactions: (state) => {
            state.idealTransactionsReport = null;
            state.idealTransactionsReportStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get open payments report
        builder
            .addCase(fetchIdealTransasctionsReport.pending, (state) => {
                state.idealTransactionsReportStatus = 'loading';
            })
            .addCase(fetchIdealTransasctionsReport.fulfilled, (state, action) => {
                state.idealTransactionsReport = action.payload;
                state.idealTransactionsReportStatus = 'success';
            })
            .addCase(fetchIdealTransasctionsReport.rejected, (state) => {
                state.idealTransactionsReportStatus = 'failed';
            });
    }
});

export const { resetIdealTransactions } = reportIdealTransactionsSlice.actions;


export default reportIdealTransactionsSlice.reducer;