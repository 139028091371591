import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { ListItemBackground, TableHeader } from '../../../app/AppStyles';
import { DisplayName } from '../../../app/AppUtils';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import PageLayout from '../../subcomponents/PageLayout';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { fetchSearchSchoolclassStudents } from './searchSchoolclassSlice';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ReportIcon from '@mui/icons-material/Report';

export default function SearchClassResultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchClassStudents, searchClassStudentsStatus } = useSelector((state) => state.search.schoolclass);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment, defaultTheme } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const [showTJL, setShowTJL] = useState(false);

    useEffect(() => {
        if (appSettings?.appVersion === 1) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment?.[0].themeSettings?.tjlEnabled)
                    setShowTJL(true);
            } else {
                if (defaultTheme?.tjlEnabled)
                    setShowTJL(true);
            };
        }
    }, [appSettings, siomSettings]);

    useEffect(() => {
        if (siomSettings.useMultipleDepartments) {
            if (activeDepartment !== null) {
                dispatch(fetchSearchSchoolclassStudents({ classId: id, departmentId: activeDepartment[0].id }));
            }
        } else {
            dispatch(fetchSearchSchoolclassStudents({ classId: id, departmentId: null }));
        };
    }, [dispatch, id, siomSettings.useMultipleDepartments, activeDepartment]);

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(-1);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchClassStudents?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? searchClassStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchClassStudents).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">
                                <Link to={`/zoeken/student/${item.id}`} style={{ color: 'black' }}>
                                    {item.studentData.number}
                                </Link>
                            </TableCell>
                            <TableCell align="left">{DisplayName(item.studentData.firstName, item.studentData.middleName, item.studentData.lastName)}</TableCell>
                            <TableCell align="center" sx={{ color: green[500] }}>{item.studentProgress ? item.studentProgress?.totalPoints?.achievedPoints : "-"}</TableCell>
                            <TableCell align="center" sx={{ color: yellow[700] }}>{item.studentProgress ? item.studentProgress?.totalPoints?.subscribedPoints : "-"}</TableCell>
                            <TableCell align="center" sx={{ color: red[500] }}>{item.studentProgress ? item.studentProgress?.totalPoints?.neededPoints : "-"}</TableCell>
                            <TableCell align="center">{item.studentProgress ? item.studentProgress?.progressFlags?.flagsYellow : "-"}</TableCell>
                            <TableCell align="center">{item.studentProgress ? item.studentProgress?.progressActivities?.some((item) => item.status === false) ? <ReportIcon color="error" /> : <CheckCircleIcon color="success" /> : "-"}</TableCell>
                            {siomSettings?.enableRequiredPrograms &&
                                <TableCell align="center">
                                    <CircleIcon sx={{
                                        color: item.studentProgress ? (
                                            item.studentProgress?.requiredPoints?.requiredPointsStatus.toLowerCase() === "green" ? green[500] :
                                                item.studentProgress?.requiredPoints?.requiredPointsStatus.toLowerCase() === "yellow" ? yellow[700] : red[500]
                                        ) : grey[500]
                                    }} />
                                </TableCell>
                            }
                            {showTJL &&
                                <TableCell align="center">
                                    <CircleIcon sx={{
                                        color: item.studentProgress ? (item.studentProgress?.progressFlags.lifestyleStatus === 2 ? green[500] :
                                            item.studentProgress?.progressFlags.lifestyleStatus === 1 ? yellow[700] : red[500]) : grey[500]
                                    }} />
                                </TableCell>
                            }
                            <TableCell align="left">
                                <IconButton onClick={() => navigate(`/zoeken/student/${item.id}`)}>
                                    <ArrowForwardIosIcon color="secondary" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}

                    {(rowsPerPage > 0 ? searchClassStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchClassStudents).length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 63 * emptyRows }}>
                            <TableCell colSpan={12} />
                        </TableRow>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: searchClassStudents?.length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: searchClassStudents?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={searchClassStudents?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <PageLayout title={searchClassStudentsStatus === "success" ? `${!!searchClassStudents[0]?.studentData?.extraInfo?.class ? searchClassStudents[0]?.studentData?.extraInfo?.class : resourceFinal[appLanguage].words.CLASS}` : `${resourceFinal[appLanguage].words.CLASS}`} content={
            <ContentLoader successCondition={searchClassStudentsStatus === "success"} errorCondition={searchClassStudentsStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader, flexGrow: 1 }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS} {resourceFinal[appLanguage].words.POINTS_ACHIEVED.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS} {resourceFinal[appLanguage].words.POINTS_SUBSCRIBED.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS} {resourceFinal[appLanguage].words.POINTS_NEEDED.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.FLAGS_YELLOW_MULT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ATTENDANCE}</TableCell>
                                    {siomSettings?.enableRequiredPrograms && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.REQUIRED_PROGRAM}</TableCell>}
                                    {showTJL && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.FLAGS_TJL}</TableCell>}
                                    <TableCell align="center" padding="checkbox"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                    <BackButton desktop overlay />
                </Box>
            } />
        } bottomBar fullScreen />
    );
}
