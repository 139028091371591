import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchWintersportReport = createAsyncThunk(
    '/api/employeeReports/Wintersport/performanceId',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchOpenPaymentsReport = createAsyncThunk(
    '/api/employeeReports/wintersport/openPayments',
    async (performanceIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/openPayments`, {
            headers: new Headers({
                'Content-Type': "application/json",
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(performanceIds),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchUnsubscriptionsReport = createAsyncThunk(
    '/api/employeeReports/wintersport/unsubscriptions',
    async (performanceIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/unsubscriptions`, {
            headers: new Headers({
                'Content-Type': "application/json",
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(performanceIds),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportWintersportSlice = createSlice({
    name: 'reportWintersport',
    initialState: {
        wintersportReport: null,
        wintersportReportStatus: null,
        openPaymentsReport: null,
        openPaymentsReportStatus: null,
        unsubscriptionsReport: null,
        unsubscriptionsReportStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get wintersport report
        builder
            .addCase(fetchWintersportReport.pending, (state) => {
                state.wintersportReportStatus = 'loading';
            })
            .addCase(fetchWintersportReport.fulfilled, (state, action) => {
                state.wintersportReport = action.payload;
                state.wintersportReportStatus = 'success';
            })
            .addCase(fetchWintersportReport.rejected, (state) => {
                state.wintersportReportStatus = 'failed';
            });

        // Get open payments report
        builder
            .addCase(fetchOpenPaymentsReport.pending, (state) => {
                state.openPaymentsReportStatus = 'loading';
            })
            .addCase(fetchOpenPaymentsReport.fulfilled, (state, action) => {
                state.openPaymentsReport = action.payload;
                state.openPaymentsReportStatus = 'success';
            })
            .addCase(fetchOpenPaymentsReport.rejected, (state) => {
                state.openPaymentsReportStatus = 'failed';
            });

        // Get unsubscriptions report
        builder
            .addCase(fetchUnsubscriptionsReport.pending, (state) => {
                state.unsubscriptionsReportStatus = 'loading';
            })
            .addCase(fetchUnsubscriptionsReport.fulfilled, (state, action) => {
                state.unsubscriptionsReport = action.payload;
                state.unsubscriptionsReportStatus = 'success';
            })
            .addCase(fetchUnsubscriptionsReport.rejected, (state) => {
                state.unsubscriptionsReportStatus = 'failed';
            });
    }
});

//export const { } = reportWintersportSlice.actions;

export default reportWintersportSlice.reducer;