import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium } from '../../../../app/AppStyles';
import ScheduleDisplayAlt from '../../../schedule/ScheduleDisplayAlt';
import ContentLoader from '../../../subcomponents/ContentLoader';
import { fetchSearchStudentSchedule } from '../searchStudentSlice';

export default function SearchStudentScheduleTab({ studentId }) {
    const dispatch = useDispatch();

    const { searchStudentScheduleStatus } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    // Get student schedule data
    useEffect(() => {
        dispatch(fetchSearchStudentSchedule(studentId));
    }, [dispatch, siomSettings, activeDepartment, studentId]);

    return (
        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
            <ContentLoader successCondition={searchStudentScheduleStatus === 'success'} errorCondition={searchStudentScheduleStatus === 'failed'} content={
                <ScheduleDisplayAlt pagination employeeMode />
            } />
        </Card>
    );
}
