import { Box, Button, ButtonGroup, Card, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../app/AppStyles';
import { HandleImageUpload } from '../../app/AppUtils';
import ContentLoader from '../subcomponents/ContentLoader';
import { fetchDeclaredTravelCosts, fetchTravelCostDeclerationOptions, postDeclaration, putDeclaration, resetPostDeclarationStatus, resetPutDeclarationStatus, setFailedDeclaration } from './travelCostSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function TravelDeclarationEditCard({ handleClose, addNew, resubmit }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedDeclaration, showFailed, postDeclarationStatus, putDeclarationStatus, getDeclarationStatus } = useSelector((state) => state.travelCost);

    const [changed, setChanged] = useState(false);
    const [newFilled, setNewFilled] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putDeclarationStatus === "success") {
            setFailedDeclaration(false);
            dispatch(resetPutDeclarationStatus());
            dispatch(fetchTravelCostDeclerationOptions());
            dispatch(fetchDeclaredTravelCosts());
            handleClose();
        } else if (putDeclarationStatus === "failed") {
            setFailedDeclaration(true);
        };
    }, [dispatch, handleClose, putDeclarationStatus]);

    // Post status callback
    useEffect(() => {
        if (postDeclarationStatus === "success") {
            setFailedDeclaration(false);
            dispatch(resetPostDeclarationStatus());
            dispatch(fetchTravelCostDeclerationOptions());
            dispatch(fetchDeclaredTravelCosts());
            handleClose();
        } else if (postDeclarationStatus === "failed") {
            setFailedDeclaration(true);
        };
    }, [dispatch, handleClose, postDeclarationStatus]);

    // Property states
    const [newCost, setNewCost] = useState("");
    const [errorCost, setErrorCost] = useState(null);
    const [newDistance, setNewDistance] = useState("");
    const [newTransport, setNewTransport] = useState("");
    const [newStudentRemark, setNewStudentRemark] = useState("");
    const [newImage, setNewImage] = useState("");
    const [imageName, setImageName] = useState(null);
    const [errorImage, setErrorImage] = useState(null);

    // Upload image handling
    function handleImageChange(event) {
        setErrorImage(null);
        var imagePromise = HandleImageUpload(event);

        imagePromise.then(
            (value) => {
                setImageName(value?.name);
                setNewImage(value?.image);
            },
            (error) => {
                (error.message === "SIZE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_TOO_LARGE));
                (error.message === "TYPE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_NOT_IMAGE));
            });
    };

    // Selected declaration state update 
    useEffect(() => {
        if (!addNew && selectedDeclaration) {
            setNewCost(selectedDeclaration?.cost);
            setNewDistance(selectedDeclaration?.distance);
            setNewTransport(selectedDeclaration?.transportType);
            setNewStudentRemark(selectedDeclaration?.studentRemark);
            setNewImage(selectedDeclaration?.evidenceImage);
        };
    }, [selectedDeclaration]);

    // Existing object changed state 
    useEffect(() => {
        if (
            newCost !== selectedDeclaration?.cost ||
            newDistance !== selectedDeclaration?.distance ||
            newTransport !== selectedDeclaration?.transportType ||
            newStudentRemark !== selectedDeclaration?.studentRemark ||
            newImage !== selectedDeclaration?.evidenceImage
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedDeclaration, newCost, newDistance, newTransport, newStudentRemark, newImage]);

    // New object required state
    useEffect(() => {
        if (newCost && newImage) {
            setNewFilled(true);
        } else {
            setNewFilled(false);
        };
    }, [newCost, newDistance, newTransport, newStudentRemark, newImage]);

    // Add click function (check required properties)
    const addClick = () => {
        if (!newCost) return setErrorCost(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorCost(null);

        if (!newImage) return setErrorImage(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorImage(null);

        dispatch(postDeclaration({
            momentId: selectedDeclaration.momentId,
            studentId: user.studentId,
            cost: newCost,
            distance: newDistance,
            transportType: newTransport,
            StudentRemark: newStudentRemark,
            evidenceImage: newImage,
            createdBy: `AP/${user.number}`,
        }));
    };

    // Update click function (check required properties)
    const updateClick = () => {
        if (!newCost) return setErrorCost(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorCost(null);

        if (!newImage) return setErrorImage(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorImage(null);

        dispatch(putDeclaration({
            declarationId: selectedDeclaration.id,
            cost: newCost,
            distance: newDistance,
            transportType: newTransport,
            StudentRemark: newStudentRemark,
            evidenceImage: newImage,
            modifiedBy: `AP/${user.number}`,
        }));
    };

    // Resubmit click function (check required properties)
    const resubmitClick = () => {
        if (!newCost) return setErrorCost(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorCost(null);

        if (!newImage) return setErrorImage(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorImage(null);

        dispatch(postDeclaration({
            momentId: selectedDeclaration.moment.momentId,
            studentId: user.studentId,
            cost: newCost,
            distance: newDistance,
            transportType: newTransport,
            StudentRemark: newStudentRemark,
            evidenceImage: newImage,
            prevDeclared: selectedDeclaration.id,
            createdBy: `AP/${user.number}`,
        }));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <ContentLoader successCondition={getDeclarationStatus === "success" || addNew} errorCondition={getDeclarationStatus === "failed"} content={
                    <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                        <Grid container spacing={1}>
                            <Grid size={{ xs: 12 }}>
                                <Typography variant="h6" sx={{ ...PageHeader }}>
                                    {addNew ?
                                        `${resourceFinal[appLanguage].words.TRAVEL_COSTS} ${resourceFinal[appLanguage].words.DECLARE.toLowerCase()}` :
                                        resubmit ? `${resourceFinal[appLanguage].words.DECLARATION} ${resourceFinal[appLanguage].words.RESUBMIT.toLowerCase()}` :
                                            `${resourceFinal[appLanguage].words.DECLARATION} ${resourceFinal[appLanguage].words.EDIT.toLowerCase()}`}
                                </Typography>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth required
                                    type="number"
                                    inputProps={{ step: 0.01, min: 0 }}
                                    value={newCost}
                                    label={resourceFinal[appLanguage].words.COST}
                                    onChange={(e) => setNewCost(e.target.value)}
                                    error={errorCost !== null}
                                    helperText={errorCost}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    inputProps={{ step: 0.01, min: 0 }}
                                    value={newDistance}
                                    label={`${resourceFinal[appLanguage].words.DISTANCE} (${resourceFinal[appLanguage].words.KILOMETRES})`}
                                    onChange={(e) => setNewDistance(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    value={newTransport}
                                    label={resourceFinal[appLanguage].words.TRANSPORT_TYPE}
                                    onChange={(e) => setNewTransport(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    value={newStudentRemark}
                                    label={resourceFinal[appLanguage].words.TRAVEL_STUDENTREMARK}
                                    onChange={(e) => setNewStudentRemark(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    sx={{ width: 1, height: 1 }}
                                    variant="outlined"
                                    component="label"
                                    color={errorImage ? "error" : "secondary"}
                                >
                                    {imageName ? imageName : `${resourceFinal[appLanguage].words.PROOF} ${resourceFinal[appLanguage].words.IMAGE}`}
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={handleImageChange}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                            {!addNew && <Grid size={{ xs: 12 }}>
                                <Typography variant="body1">
                                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.TRAVEL_EMPLOYEEREMARK}: </Typography>
                                    {selectedDeclaration?.remark ? selectedDeclaration?.remark : "-"}
                                </Typography>
                            </Grid>}
                            <Grid size={{ xs: 12 }}>
                                {showFailed && <>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                                </>}
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{errorImage}</Typography>
                            </Grid>
                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonGroup sx={{ justifyContent: 'center' }}>
                                    <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                    {(!addNew && !resubmit) && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                    {addNew && <Button disabled={!newFilled} variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.DECLARE}</Button>}
                                    {resubmit && <Button disabled={!changed} variant="outlined" color="primary" onClick={resubmitClick}>{resourceFinal[appLanguage].words.RESUBMIT}</Button>}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Scrollbars>
                } />
            </Card>
        </Box>
    );
}
