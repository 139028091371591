import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchEmployees = createAsyncThunk(
    "/api/employeeRoles/employees/isActive",
    async (isActive, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/employeeRoles/employees/${isActive}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putEmployee = createAsyncThunk(
    "/api/employeeRoles/put",
    async (updatedEmployee, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/employeeRoles",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedEmployee),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchEmployeeSchoolclasses = createAsyncThunk(
    "/api/employee/schoolclasses",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/employee/schoolclasses`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchEmployeeAttendances = createAsyncThunk(
    "/api/employee/attendances",
    async (departmentId, { rejectWithValue }) => {
        const urlParams = departmentId !== null ? `api/employee/attendances/${departmentId}` : `api/employee/attendances`;
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + urlParams,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const employeeSlice = createSlice({
    name: "employee",
    initialState: {
        employee: null,
        employees: null,
        putEmployeeStatus: null,
        employeesStatus: null,
        employeeSchoolclasses: null,
        employeeSchoolclassesStatus: null,
        employeeAttendances: null,
        employeeAttendancesStatus: null,
    },
    reducers: {
        selectEmployee: (state, action) => {
            state.employee = action.payload;
        },
        resetEmployeeStatus: (state, action) => {
            state.putEmployeeStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get all employees
        builder
            .addCase(fetchEmployees.pending, (state) => {
                state.employeesStatus = "loading";
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.employees = action.payload;
                state.employeesStatus = "success";
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.employeesStatus = "failed";
            });
        // put  employees
        builder
            .addCase(putEmployee.pending, (state) => {
                state.putEmployeeStatus = "loading";
            })
            .addCase(putEmployee.fulfilled, (state, action) => {
                state.putEmployeeStatus = "success";
            })
            .addCase(putEmployee.rejected, (state, action) => {
                state.putEmployeeStatus = "failed";
            });

        // Get employee schoolclasses
        builder
            .addCase(fetchEmployeeSchoolclasses.pending, (state) => {
                state.employeeSchoolclassesStatus = "loading";
            })
            .addCase(fetchEmployeeSchoolclasses.fulfilled, (state, action) => {
                state.employeeSchoolclasses = action.payload;
                state.employeeSchoolclassesStatus = "success";
            })
            .addCase(fetchEmployeeSchoolclasses.rejected, (state, action) => {
                state.employeeSchoolclassesStatus = "failed";
            });

        // Get employee attendances todo
        builder
            .addCase(fetchEmployeeAttendances.pending, (state) => {
                state.employeeAttendancesStatus = "loading";
            })
            .addCase(fetchEmployeeAttendances.fulfilled, (state, action) => {
                state.employeeAttendances = action.payload;
                state.employeeAttendancesStatus = "success";
            })
            .addCase(fetchEmployeeAttendances.rejected, (state, action) => {
                state.employeeAttendancesStatus = "failed";
            });
    },
});

export const { selectEmployee, resetEmployeeStatus } = employeeSlice.actions;

export default employeeSlice.reducer;
