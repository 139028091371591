import { Box, Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, Pagination, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import SearchField from '../subcomponents/SearchField';
import MessageCard from './MessageCard';
import { clearMessages, fetchMessages } from './messageSlice';

export default function MessagesTab() {
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { messages, messagesStatus } = useSelector((state) => state.messages);

    const [searchMode, setSearchMode] = useState('Subject');
    const [filter, setFilter] = useState("");

    const amount = 5;
    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    useEffect(() => {
        if (messagesStatus === "success") {
            const size = messages?.length;
            setItemsLeft(size - amount);
        }
    }, [messagesStatus, amount, messages]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleRadioClick = (value) => {
        setSearchMode(value);
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleSearchClick = () => {
        dispatch(fetchMessages({ type: searchMode, value: filter }))
    };

    const handleClearClick = () => {
        setFilter("");
        inputRef.current.value = "";
        dispatch(clearMessages());
    };

    return (
        <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
            <Box sx={{ flex: '1 1 auto' }}>
                <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{ p: 1, ...ListItemBackground, display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center' }}>
                                <FormControl onClick={(e) => handleRadioClick(e.target.value)}>
                                    <FormLabel>{resourceFinal[appLanguage].words.SEARCH} {resourceFinal[appLanguage].words.ON.toLowerCase()}</FormLabel>
                                    <RadioGroup defaultValue="subject" row>
                                        <FormControlLabel value="subject" control={<Radio />} label={resourceFinal[appLanguage].words.SUBJECT} />
                                        <FormControlLabel value="sendto" control={<Radio />} label={resourceFinal[appLanguage].words.MESSAGE_RECEIVER} />
                                        <FormControlLabel value="sendfrom" control={<Radio />} label={resourceFinal[appLanguage].words.MESSAGE_SENDER} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box sx={{ p: 1, ...ListItemBackground, display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center' }}>
                                <SearchField func={handleSearchChange} textRef={inputRef} />
                            </Box>
                            <Box sx={{ p: 1, ...ListItemBackground, display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center' }}>
                                <ButtonGroup variant="outlined">
                                    <Button color="error" disabled={!filter} onClick={handleClearClick}>{resourceFinal[appLanguage].words.CLEAR}</Button>
                                    <Button color="secondary" onClick={handleSearchClick}>{resourceFinal[appLanguage].words.SEARCH}</Button>
                                </ButtonGroup>
                            </Box>
                        </Stack>
                    </Grid>

                    {messagesStatus && <ContentLoader successCondition={messagesStatus === "success"} errorCondition={messagesStatus === "failed"} content={
                        (messages?.slice((page - 1) * amount, page * amount))?.map((item) =>
                            <Grid key={item.id} size={{ xs: 12 }}>
                                <MessageCard item={item} />
                            </Grid>
                        )} />}
                    <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {(messages?.length === 0 && messagesStatus === "success") && <Typography variant="h6">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography>}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {(itemsLeft > 0 && !isNaN(pages) && messagesStatus === "success") &&
                    <Box sx={{ mt: 'auto', p: 1 }}>
                        <Pagination count={pages} page={page} onChange={handleChange} defaultPage={1} color="secondary" />
                    </Box>}
            </Box>
        </Box>
    );
}
