import { Box, Button, ButtonGroup, Card, Checkbox, FormLabel, Radio, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from '../../../../../app/AppStyles';
import { GetDate, GetTime } from '../../../../../app/AppUtils';
import { fetchSearchStudentAttendances, putStudentAttendance, setFailedAttendance } from '../../searchStudentSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function SearchStudentAttendanceEditCard({ handleClose, studentId, departmentId }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { putStudentAttendanceStatus, selectedAttendance, failedAttendance } = useSelector((state) => state.search.student);
    const { appSettings } = useSelector((state) => state.settings.app);

    // Property states
    const [newStudentAttendance, setNewStudentAttendance] = useState({});
    const [changed, setChanged] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putStudentAttendanceStatus === "success") {
            dispatch(setFailedAttendance(false));
            dispatch(fetchSearchStudentAttendances({ studentId: studentId, departmentId: departmentId }));
            handleClose();
        } else if (putStudentAttendanceStatus === "failed") {
            dispatch(setFailedAttendance(true));
        };
    }, [dispatch, handleClose, putStudentAttendanceStatus]);

    // Selected program state update 
    useEffect(() => {
        setNewStudentAttendance(selectedAttendance);
    }, [selectedAttendance]);

    // Keep track of changed state 
    useEffect(() => {
        if (newStudentAttendance !== selectedAttendance) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [newStudentAttendance, selectedAttendance]);

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        newStudentAttendance.ModifiedBy = `AP/${user.number}`;
        dispatch(putStudentAttendance(newStudentAttendance));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.ATTENDANCE} {resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.STUDENT}</FormLabel>
                            <TextField
                                disabled
                                fullWidth
                                value={`${newStudentAttendance.studentFullname} (${newStudentAttendance.studentNumber})`}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.ACTIVITY}</FormLabel>
                            <TextField
                                disabled
                                fullWidth
                                value={newStudentAttendance.activityName}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.DATE}</FormLabel>
                            <TextField
                                disabled
                                fullWidth
                                value={`${GetDate(newStudentAttendance.momentDate)} (${GetTime(newStudentAttendance.momentDate)})`}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.ATTENDANCE}</FormLabel>
                            <Radio
                                checked={newStudentAttendance.present === false}
                                onChange={(event) => setNewStudentAttendance({
                                    ...newStudentAttendance,
                                    present: false
                                })}
                                value={false}
                                color="error" sx={{ color: 'error.main' }}
                            />
                            <Radio
                                checked={newStudentAttendance.present === true}
                                onChange={(event) => setNewStudentAttendance({
                                    ...newStudentAttendance,
                                    present: true
                                })}
                                value={true}
                                color="success" sx={{ color: 'success.main' }}
                            />
                        </Grid>
                        {appSettings?.appVersion === 2 && <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.HOURS}</FormLabel>
                            <TextField
                                disabled={!newStudentAttendance.present && !newStudentAttendance.dispensation}
                                type="number"
                                inputProps={{ step: 0.5, min: 0 }}
                                defaultValue={newStudentAttendance.workedHours}
                                onChange={(event) => setNewStudentAttendance({
                                    ...newStudentAttendance,
                                    workedHours: event.target.value
                                })}
                            />
                        </Grid>}
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.DISPENSATION}</FormLabel>
                            <Checkbox
                                defaultChecked={selectedAttendance?.dispensation}
                                onChange={(event) => setNewStudentAttendance({
                                    ...newStudentAttendance,
                                    dispensation: event.target.checked ? true : false
                                })}
                                colors="secondary" disabled={newStudentAttendance.present !== false}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].words.REMARK}</FormLabel>
                            <TextField
                                multiline
                                fullWidth
                                defaultValue={newStudentAttendance.remark}
                                onChange={(event) => setNewStudentAttendance({
                                    ...newStudentAttendance,
                                    remark: event.target.value
                                })}
                            />
                        </Grid>
                        {failedAttendance && <Grid size={{ xs: 12 }}>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                        </Grid>}
                        <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}