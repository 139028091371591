import { Box, Modal } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GetDate } from '../../app/AppUtils';
import ListItemCard from '../subcomponents/ListItemCard';
import StudentInfoEditCard from './StudentInfoEditCard';

import CakeIcon from '@mui/icons-material/Cake';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import HeightIcon from '@mui/icons-material/Height';
import LuggageIcon from '@mui/icons-material/Luggage';
import Man4Icon from '@mui/icons-material/Man4';
import MedicationIcon from '@mui/icons-material/Medication';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function StudentInfoContentV1({ content, editable }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { studentInfoSettings } = useSelector((state) => state.student);
    const { studentInfo } = useSelector((state) => state.search.student);

    const [open, setOpen] = useState(false);
    const [objectToChange, setObjectToChange] = useState();

    const handleOpen = (object) => {
        setOpen(true);
        setObjectToChange(object);
    };

    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={1} alignItems="flex-start">
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.number}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_STUDENTNUMBER}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<PersonIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={`${content?.firstName} ${content?.middleName} ${content?.lastName}`}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_NAME}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<GroupsIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.class ? content?.extraInfo?.class : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CLASS}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<Man4Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.mentor ? content?.extraInfo?.mentor : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_MENTOR}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.crebo}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CREBO}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.cohort}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_COHORT}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<CakeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={GetDate(content?.dateOfBirth)}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.workEmail}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_SCHOOL}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<PhoneIcon />}
                        avatarColor={'secondary.main'}
                        altBgColor={{ bgcolor: 'white.main' }}
                        primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'mobilePhone',
                                label: resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER,
                                currentValue: content.privatePhone,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_MOBILE_PHONE,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor="secondary.main"
                        primaryText={content?.privateEmail ? content.privateEmail : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}
                        altBgColor={editable ? { bgcolor: 'white.main' } : undefined}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'email',
                                label: resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE,
                                currentValue: content.privateEmail,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_EMAIL,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                {(studentInfoSettings?.wintersportInfoRequired || studentInfo) && <>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<CoronavirusIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.allergy}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_ALLERGY}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'allergy',
                                    label: resourceFinal[appLanguage].words.USER_DATA_ALLERGY,
                                    currentValue: content?.extraInfo?.allergy,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'default'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<DownhillSkiingIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.skiExperience}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'skiExperience',
                                    label: resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE,
                                    currentValue: content?.extraInfo?.skiExperience,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'skiExperience'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<SnowboardingIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.sport}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_SPORT}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'sport',
                                    label: resourceFinal[appLanguage].words.USER_DATA_SPORT,
                                    currentValue: content?.extraInfo?.sport,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'sport'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<MedicationIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.medication}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_MEDICATION}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'medication',
                                    label: resourceFinal[appLanguage].words.USER_DATA_MEDICATION,
                                    currentValue: content?.extraInfo?.medication,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'default'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<ContactEmergencyIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.emergencyContactName}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NAME}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'emergencyContactName',
                                    label: resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NAME,
                                    currentValue: content?.extraInfo?.emergencyContactName,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'default'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<PhoneIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.emergencyContactNumber}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NUMBER}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'emergencyContactNumber',
                                    label: resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NUMBER,
                                    currentValue: content?.extraInfo?.emergencyContactNumber,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'default'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<SquareFootIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.shoeSizeEU}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_SHOE_SIZE}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'shoeSizeEU',
                                    label: resourceFinal[appLanguage].words.USER_DATA_SHOE_SIZE,
                                    currentValue: content?.extraInfo?.shoeSizeEU,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'number'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<HeightIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.height}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_HEIGHT}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'height',
                                    label: resourceFinal[appLanguage].words.USER_DATA_HEIGHT,
                                    currentValue: content?.extraInfo?.height,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'number'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<MonitorWeightIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.weight}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_WEIGHT}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'weight',
                                    label: resourceFinal[appLanguage].words.USER_DATA_WEIGHT,
                                    currentValue: content?.extraInfo?.weight,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'number'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ListItemCard
                            avatarIcon={<LuggageIcon />}
                            avatarColor={'secondary.main'}
                            primaryText={content?.extraInfo?.ownedMaterial}
                            secondaryText={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL}
                            {...(editable && {
                                action: () => handleOpen({
                                    field: 'ownedMaterial',
                                    label: resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL,
                                    currentValue: content?.extraInfo?.ownedMaterial,
                                    errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    type: 'ownedMaterial'
                                }),
                                actionIcon: <EditIcon color="secondary" />,
                            })}
                        />
                    </Grid>
                </>}
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <StudentInfoEditCard studentInfo={content} object={objectToChange} />
                </Box>
            </Modal>
        </>
    );
}
