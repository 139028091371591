import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchMomentReport = createAsyncThunk(
    '/api/employeeReports/moments/startDate/endDate',
    async ({ startDate, endDate }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/moments/${startDate}/${endDate}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportMomentsSlice = createSlice({
    name: 'reportMoments',
    initialState: {
        momentReport: null,
        momentReportStatus: null,
    },
    reducers: {
        resetReportMoments: (state) => {
            state.momentReport = null;
            state.momentReportStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get moments report
        builder
            .addCase(fetchMomentReport.pending, (state) => {
                state.momentReportStatus = 'loading';
            })
            .addCase(fetchMomentReport.fulfilled, (state, action) => {
                state.momentReport = action.payload;
                state.momentReportStatus = 'success';
            })
            .addCase(fetchMomentReport.rejected, (state) => {
                state.momentReportStatus = 'failed';
            });
    }
});

export const { resetReportMoments } = reportMomentsSlice.actions;

export default reportMomentsSlice.reducer;