import { Box, Checkbox, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ListItemBackground, TableHeader } from '../../app/AppStyles';
import { SearchKeyFilter, GetDate, GetTime } from '../../app/AppUtils';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import TablePaginationActions from '../subcomponents/TablePaginationActions';
import { fetchAllAttendances, fetchMomentAttendance } from './attendanceSlice';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function AttendancePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { attendances, attendancesStatus } = useSelector((state) => state.attendance);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);

    const [filter, setFilter] = useState("");
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (siomSettings) {
            const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;
            dispatch(fetchAllAttendances({ completed: completed, departmentId: departmentId }));
        }
    }, [dispatch, completed, siomSettings, activeDepartment]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["activityName", "performanceNumber"], filter);
    }

    const FilterFragment = () => {
        return (
            <>
                <FormGroup sx={{ width: 1 }}>
                    <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={completed} onChange={() => setCompleted(!completed)} />} label={`${resourceFinal[appLanguage].words.COMPLETED}`} />
                </FormGroup>
            </>
        );
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - attendances?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const attendanceClick = (item) => {
            dispatch(fetchMomentAttendance(item.momentId));
            navigate(`/presentie/moment/${item.momentId}`);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? attendances.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : attendances.filter(handleFilterResult)).map(item =>
                        <TableRow key={item.momentId} hover onClick={() => attendanceClick(item)} sx={{ cursor: 'pointer', '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.activityName}</TableCell>
                            <TableCell align="center">{item.performanceNumber}</TableCell>
                            <TableCell align="center">{item.locationName}</TableCell>
                            <TableCell align="center">{GetDate(item.startTime)} ({GetTime(item.startTime)})</TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.completed} color="secondary" /></TableCell>
                            <TableCell align="center"><ArrowForwardIosIcon color="secondary" /></TableCell>
                        </TableRow>
                    )}

                    {(rowsPerPage > 0 ? attendances.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : attendances.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={6}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: attendances?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                    value: attendances?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={attendances?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.ATTENDANCE}`} content={
            <ContentLoader successCondition={attendancesStatus === "success"} errorCondition={attendancesStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                    <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.COMPLETED}</TableCell>
                                    <TableCell align="center" padding="checkbox"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />
        } contentLeft={
            <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            <FilterButton filters={<FilterFragment />} />
        } />
    );
}
