import HistoryDisplay from '../../../History/HistoryDisplay';

export default function SearchStudentHistoryTab({ studentId }) {
    return (
        <>
            <HistoryDisplay studentId={studentId} />
        </>
    );
}

