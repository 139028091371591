import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import { AddZero, SearchKeyFilter } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import { getAvailableSubprograms, getProgramSubprograms, postProgramSubprograms, setFailedProgramSubprograms } from './programManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

const emptyProgramSubprogram = {
    programId: null,
    subprogramIds: [],
    MinPoints: null,
    MaxPoints: null,
    MaxAbsence: null,
    StartTime: null,
    EndTime: null,
    GreenFlagsProgress: null,
};

export default function ProgramSubprogramAddCard({ handleClose }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedProgram, postProgramSubprogramsStatus, showProgramSubprogramFailed, availableSubprograms, availableSubprogramsStatus } = useSelector((state) => state.management.program);

    // Property states
    const [newProgramSubprogram, setNewProgramSubprogram] = useState(emptyProgramSubprogram);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState();
    const [emptyDatesError, setEmptyDatesError] = useState();
    const [emptySubprogramsError, setEmptySubprogramsError] = useState();
    const [triedSubmit, setTriedSubmit] = useState(false);
    const [filter, setFilter] = useState("");
    const [newSubprogramIds, setNewSubprogramIds] = useState([]);

    // Get subprograms
    useEffect(() => {
        dispatch(getAvailableSubprograms(selectedProgram.id));
    }, [dispatch, selectedProgram]);

    // Post status callback
    useEffect(() => {
        if (postProgramSubprogramsStatus === "success") {
            dispatch(setFailedProgramSubprograms(false));
            dispatch(getProgramSubprograms(selectedProgram.id));
            handleClose();
        } else if (postProgramSubprogramsStatus === "failed") {
            dispatch(setFailedProgramSubprograms(true));
        };
    }, [dispatch, handleClose, selectedProgram, postProgramSubprogramsStatus]);

    // Keep track of changed state 
    useEffect(() => {
        if (newProgramSubprogram !== emptyProgramSubprogram)
            setChanged(true);
        else
            setChanged(false);
    }, [newProgramSubprogram]);

    // Check start/end date range
    useEffect(() => {
        if (Date.parse(newProgramSubprogram.startTime) >= Date.parse(newProgramSubprogram.endTime)) {
            setError(true);
        } else {
            setError(false);
        };
    }, [newProgramSubprogram]);

    // Check start/end date are empty
    useEffect(() => {
        if (triedSubmit && (!newProgramSubprogram.startTime || !newProgramSubprogram.endTime))
            setEmptyDatesError(true);
        else
            setEmptyDatesError(false);
    }, [newProgramSubprogram, triedSubmit]);

    // Check start/end date are empty
    useEffect(() => {
        if (triedSubmit && newSubprogramIds.length === 0)
            setEmptySubprogramsError(true);
        else
            setEmptySubprogramsError(false);
    }, [newSubprogramIds, triedSubmit]);

    // Add click function
    const addClick = () => {
        if (error)
            return;

        if (!newProgramSubprogram.startTime || !newProgramSubprogram.endTime) {
            setTriedSubmit(true);
            return;
        };

        if (newSubprogramIds.length === 0) {
            setTriedSubmit(true);
            return;
        };

        newProgramSubprogram.greenFlagsProgress = siomSettings?.flagProgress;
        newProgramSubprogram.subprogramIds = newSubprogramIds;
        newProgramSubprogram.programId = selectedProgram.id;
        newProgramSubprogram.createdBy = `AP/${user.number}`;

        dispatch(postProgramSubprograms(newProgramSubprogram));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["name"], filter);
    }

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < availableSubprograms?.filter(handleFilterResult).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === availableSubprograms?.filter(handleFilterResult).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = availableSubprograms?.filter(handleFilterResult).map((n) => n.id);
            setNewSubprogramIds(newSelected);
            return;
        };

        setNewSubprogramIds([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newSubprogramIds.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newSubprogramIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newSubprogramIds.slice(1));
        } else if (selectedIndex === newSubprogramIds.length - 1) {
            newSelected = newSelected.concat(newSubprogramIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newSubprogramIds.slice(0, selectedIndex),
                newSubprogramIds.slice(selectedIndex + 1),
            );
        };

        setNewSubprogramIds(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - availableSubprograms?.filter(handleFilterResult).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => newSubprogramIds.findIndex(x => x === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.SUBPROGRAMS} {resourceFinal[appLanguage].words.LINK2.toLowerCase()}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                label={siomSettings?.flagProgress ? resourceFinal[appLanguage].words.MOMENTS_MIN : resourceFinal[appLanguage].words.POINTS_MIN}
                                onChange={(event) =>
                                    siomSettings?.flagProgress ?
                                        setNewProgramSubprogram({ ...newProgramSubprogram, minMoments: event.target.value ? event.target.value : null })
                                        :
                                        setNewProgramSubprogram({ ...newProgramSubprogram, minPoints: event.target.value ? event.target.value : null })
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                label={siomSettings?.flagProgress ? resourceFinal[appLanguage].words.MOMENTS_MAX : resourceFinal[appLanguage].words.POINTS_MAX}
                                onChange={(event) =>
                                    siomSettings?.flagProgress ?
                                        setNewProgramSubprogram({ ...newProgramSubprogram, maxMoments: event.target.value ? event.target.value : null })
                                        :
                                        setNewProgramSubprogram({ ...newProgramSubprogram, maxPoints: event.target.value ? event.target.value : null })
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}*`}
                                    onChange={(value) => setNewProgramSubprogram({
                                        ...newProgramSubprogram,
                                        startTime: value ? `${AddZero(value.$y)}-${AddZero(Number(value.$M) + 1)}-${AddZero(value.$D)}T${AddZero(value.$H)}:${AddZero(value.$m)}:${AddZero(value.$s)}` : null
                                    })} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.TIME.toLowerCase()}*`}
                                    onChange={(value) => setNewProgramSubprogram({
                                        ...newProgramSubprogram,
                                        endTime: value ? `${AddZero(value.$y)}-${AddZero(Number(value.$M) + 1)}-${AddZero(value.$D)}T${AddZero(value.$H)}:${AddZero(value.$m)}:${AddZero(value.$s)}` : null
                                    })} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                label={resourceFinal[appLanguage].words.ABSENCE_MAX}
                                onChange={(event) => setNewProgramSubprogram({
                                    ...newProgramSubprogram,
                                    maxAbsence: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        {siomSettings?.enableRequiredPrograms && <Grid size={{ xs: 6 }}>
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.REQUIRED} ${resourceFinal[appLanguage].words.SUBPROGRAM.toLowerCase()}`}
                                control={<Checkbox
                                    onChange={(event) => setNewProgramSubprogram({
                                        ...newProgramSubprogram,
                                        isRequired: event.target.checked ? true : false
                                    })}
                                />}
                            />
                        </Grid>}
                        <Grid size={{ xs: 12 }}>
                            <ContentLoader successCondition={availableSubprogramsStatus === "success"} errorCondition={availableSubprogramsStatus === "failed"} content={
                                <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                                    <SearchField func={handleSearchChange} textRef={textRef} />
                                    <TableContainer>
                                        <Table>
                                            <EnhancedTableHead numSelected={newSubprogramIds?.length} onSelectAllClick={handleSelectAllClick} rowCount={availableSubprograms?.length} />
                                            <TableBody>
                                                {(rowsPerPage > 0 ? availableSubprograms?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableSubprograms?.filter(handleFilterResult))?.map((item, index) => {
                                                    const isItemSelected = isSelected(item.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow hover onClick={(event) => handleClick(event, item.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.id} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="primary" checked={isItemSelected ?? ""} inputProps={{ 'aria-labelledby': labelId, }} />
                                                            </TableCell>
                                                            <TableCell align="left">{item.name}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {(rowsPerPage > 0 ? availableSubprograms?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableSubprograms?.filter(handleFilterResult))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                                {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        sx={{ border: 0 }}
                                                        rowsPerPageOptions={[10, 25, 50,
                                                            {
                                                                label: availableSubprograms?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : 100,
                                                                value: availableSubprograms?.filter(handleFilterResult).length < 100 ? -1 : 100
                                                            }
                                                        ]}
                                                        count={availableSubprograms ? availableSubprograms?.length : 0}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            } />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            {error && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROGRAM_MANAGEMENT_TIME}</Typography>}
                            {(emptyDatesError && triedSubmit) && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROGRAM_MANAGEMENT_EMPTY_TIME}</Typography>}
                            {(emptySubprogramsError && triedSubmit) && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROGRAM_MANAGEMENT_EMPTY_SUBPROGRAMS}</Typography>}
                            {showProgramSubprogramFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                        </Grid>
                        <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed || error || emptyDatesError || emptySubprogramsError} variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
