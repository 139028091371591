import { Box, Button, ButtonGroup, Card, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter } from '../../../app/AppUtils';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { getAllProgramLinks, getItemsToLink, postProgramLinks, resetProgramLinkStatus, setFailedProgramLink } from './linkProgramSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function LinkProgramAddCard({ handleClose }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const {
        programsToLink,
        programsToLinkStatus,
        departmentsToLink,
        departmentsToLinkStatus,
        itemsToLink,
        itemsToLinkStatus,
        postProgramLinkStatus,
        showProgramLinkFailed
    } = useSelector((state) => state.management.linkProgram);

    const [filter, setFilter] = useState("");
    const [programFilter, setProgramFilter] = useState("");
    const [changed, setChanged] = useState(false);
    const [newProgramLinks, setNewProgramLinks] = useState({});
    const [selectedType, setSelectedType] = useState("");

    const [errorProgram, setErrorProgram] = useState(null);
    const [errorDepartment, setErrorDepartment] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [errorItems, setErrorItems] = useState(null);
    const [newItemsToLink, setNewItemsToLink] = useState([]);

    // Post status callback
    useEffect(() => {
        if (postProgramLinkStatus === "success") {
            dispatch(setFailedProgramLink(false));
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SAVE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetProgramLinkStatus());
            dispatch(getAllProgramLinks());
            handleClose();
        } else if (postProgramLinkStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SAVE_ERROR}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetProgramLinkStatus());
            dispatch(setFailedProgramLink(true));
        };
    }, [dispatch, handleClose, postProgramLinkStatus]);


    // Keep track of changed state 
    useEffect(() => {
        if (Object.keys(newProgramLinks).length !== 0) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [newProgramLinks]);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newProgramLinks.programId) return setErrorProgram(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorProgram(null);

        if (siomSettings?.useMultipleDepartments && !newProgramLinks.departmentId) return setErrorDepartment(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorDepartment(null);

        if (!newProgramLinks.linkType) return setErrorType(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorType(null);

        if (newItemsToLink.length === 0) return setErrorItems(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorItems(null);

        let tempObject = { ...newProgramLinks }; // Needed to circumvent weird read only property error

        tempObject.IdsToLink = newItemsToLink;
        tempObject.createdBy = `AP/${user.number}`;

        dispatch(postProgramLinks(tempObject));
    };

    useEffect(() => {
        if (selectedType) dispatch(getItemsToLink(selectedType));
    }, [dispatch, selectedType]);

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleSearchProgramChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setProgramFilter(value);
        }, 600);
    };

    const handleProgramFilterResult = (item) => {
        return SearchKeyFilter(item, ["name"], programFilter);
    }

    const handleLinkFilterResult = (item) => {
        return SearchKeyFilter(item, ["linkedName", "linkedNumber"], filter);
    }

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < itemsToLink?.filter(handleLinkFilterResult).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === itemsToLink?.filter(handleLinkFilterResult).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    {selectedType.toLowerCase() === "student" && <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>}
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = itemsToLink?.filter(handleLinkFilterResult).map((n) => n.linkedId);
            setNewItemsToLink(newSelected);
            return;
        };

        setNewItemsToLink([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newItemsToLink.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newItemsToLink, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newItemsToLink.slice(1));
        } else if (selectedIndex === newItemsToLink.length - 1) {
            newSelected = newSelected.concat(newItemsToLink.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newItemsToLink.slice(0, selectedIndex),
                newItemsToLink.slice(selectedIndex + 1),
            );
        };

        setNewItemsToLink(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemsToLink?.filter(handleLinkFilterResult).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => newItemsToLink.findIndex(x => x === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <ContentLoader successCondition={programsToLinkStatus === "success" && departmentsToLinkStatus === "success"} errorCondition={programsToLinkStatus === "failed" && departmentsToLinkStatus === "failed"} content={
                        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.PROGRAM}{resourceFinal[appLanguage].words.LINK.toLowerCase()} {resourceFinal[appLanguage].words.ADD.toLowerCase()}</Typography>
                            <FormControl fullWidth required error={errorProgram !== null}>
                                <InputLabel>{resourceFinal[appLanguage].words.PROGRAM}</InputLabel>
                                <Select label={resourceFinal[appLanguage].words.PROGRAM} defaultValue=''
                                    onChange={(event) => {
                                        setNewProgramLinks({
                                            ...newProgramLinks,
                                            programId: event.target.value
                                        });
                                    }}>
                                    <MenuItem
                                        disableRipple
                                        dense
                                        divider
                                        value={""}
                                        onClickCapture={stopImmediatePropagation}
                                        onKeyDown={e => e.stopPropagation()}
                                    >
                                        <SearchField func={handleSearchProgramChange} textRef={textRef} />
                                    </MenuItem>
                                    {programsToLink?.filter(handleProgramFilterResult)?.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                                <FormHelperText>{errorProgram}</FormHelperText>
                            </FormControl>
                            {siomSettings?.useMultipleDepartments && <FormControl fullWidth required error={errorDepartment !== null}>
                                <InputLabel>{resourceFinal[appLanguage].words.DEPARTMENT}</InputLabel>
                                <Select label={resourceFinal[appLanguage].words.DEPARTMENT} defaultValue=''
                                    onChange={(event) => {
                                        setNewProgramLinks({
                                            ...newProgramLinks,
                                            departmentId: event.target.value
                                        });
                                    }}>

                                    {departmentsToLink?.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                                <FormHelperText>{errorDepartment}</FormHelperText>
                            </FormControl>}
                            <FormControl fullWidth required error={errorType !== null}>
                                <InputLabel>{resourceFinal[appLanguage].words.LINK}{resourceFinal[appLanguage].words.TYPE.toLowerCase()}</InputLabel>
                                <Select label={`${resourceFinal[appLanguage].words.LINK}${resourceFinal[appLanguage].words.TYPE.toLowerCase()}`} defaultValue=''
                                    onChange={(event) => {
                                        setNewProgramLinks({
                                            ...newProgramLinks,
                                            linkType: event.target.value
                                        });
                                        setNewItemsToLink([]);
                                        setSelectedType(event.target.value);
                                    }}>
                                    <MenuItem value="klas">{resourceFinal[appLanguage].words.CLASS}</MenuItem>
                                    <MenuItem value="student">{resourceFinal[appLanguage].words.STUDENT}</MenuItem>
                                </Select>
                                <FormHelperText>{errorType}</FormHelperText>
                            </FormControl>

                            <ContentLoader successCondition={itemsToLinkStatus === "success" || itemsToLinkStatus === null} errorCondition={itemsToLinkStatus === "failed"} errorCompact content={
                                <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                                    <Typography variant="h6" sx={{ ...PageHeader }}>{selectedType === "student" ? resourceFinal[appLanguage].words.STUDENTS : resourceFinal[appLanguage].words.CLASSES} {resourceFinal[appLanguage].words.LINK2.toLowerCase()}</Typography>
                                    <SearchField func={handleSearchChange} textRef={textRef} />
                                    <TableContainer>
                                        <Table>
                                            <EnhancedTableHead numSelected={newItemsToLink?.length} onSelectAllClick={handleSelectAllClick} rowCount={itemsToLink?.length} />
                                            <TableBody>
                                                {(rowsPerPage > 0 ? itemsToLink?.filter(handleLinkFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : itemsToLink?.filter(handleLinkFilterResult))?.map((item, index) => {
                                                    const isItemSelected = isSelected(item?.linkedId);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow hover onClick={(event) => handleClick(event, item.linkedId)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                                            </TableCell>
                                                            {selectedType.toLowerCase() === "student" && <TableCell align="center">{item?.linkedNumber}</TableCell>}
                                                            <TableCell align="left">{item?.linkedName}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {(rowsPerPage > 0 ? itemsToLink?.filter(handleLinkFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : itemsToLink?.filter(handleLinkFilterResult))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                                {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        sx={{ border: 0 }}
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        count={itemsToLink ? itemsToLink?.length : 0}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            } />

                            {errorItems && <>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}</Typography>
                            </>}

                            {showProgramLinkFailed && <>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                            </>}

                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed} variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </ButtonGroup>
                        </Stack>
                    } />
                </Scrollbars>
            </Card>
        </Box>
    );
}
