import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../subcomponents/PageLayout';
import ReportMomentsTab from './moments/ReportMomentsTab';
import ReportTravelCostsTab from './travelCosts/ReportTravelCostsTab';
import ReportWintersportTab from './wintersport/ReportWintersportTab';
import ReportOpenPaymentsTab from './wintersport/ReportOpenPaymentsTab';
import ReportUnsubscriptionsTab from './wintersport/ReportUnsubscriptionsTab';
import ReportIdealTransactionsTab from './idealTransactions/ReportIdealTransactionsTab';
import ReportProgressTab from './progress/ReportProgressTab';
import ReportResultTab from './progress/ReportResultTab';

export default function ReportsPage() {
    const [value, setValue] = useState(0);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { pages } = useSelector((state) => state.settings.resource.navigation);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabsList = [
        {
            label: resourceFinal[appLanguage].words.REPORT_MOMENTS,
            component: <ReportMomentsTab />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_PROGRESS,
            component: <ReportProgressTab />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_RESULT,
            component: <ReportResultTab />,
            condition: true,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_TRANSACTIONS,
            component: <ReportIdealTransactionsTab />,
            condition: siomSettings.mollieActive,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_TRAVEL_COSTS,
            component: <ReportTravelCostsTab />,
            condition: pages.includes("Reiskosten"),
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_WINTERSPORT,
            component: <ReportWintersportTab />,
            condition: appSettings?.wintersportModeEnabled,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_OPEN_PAYMENTS,
            component: <ReportOpenPaymentsTab />,
            condition: appSettings?.wintersportModeEnabled,
        },
        {
            label: resourceFinal[appLanguage].words.REPORT_UNSUBSCRIPTIONS,
            component: <ReportUnsubscriptionsTab />,
            condition: appSettings?.wintersportModeEnabled,
        },
    ]

    return (
        <PageLayout fullScreen title={resourceFinal[appLanguage].words.REPORTS_PAGE} content={
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        {tabsList
                            .filter(tab => tab.condition)
                            .map((tab, index) => (
                                <Tab key={index} label={tab.label} />
                            ))}
                    </Tabs>
                </Box>
                {tabsList
                    .filter(tab => tab.condition)
                    .map((tab, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            {tab.component}
                        </TabPanel>
                    ))}
            </>
        } bottomBar />
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 1, bgcolor: 'white.light', borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
