import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchStudentHistory = createAsyncThunk(
    '/api/history/student/studentId',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/history/student/${studentId}/${departmentId}` : `api/history/student/${studentId}`;

        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchStudentProgramHistory = createAsyncThunk(
    '/api/history/program/studentId/programId/departmentId',
    async ({ studentId, programId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/history/program/${studentId}/${programId}/${departmentId}` : `api/history/program/${studentId}/${programId}`;

        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);


export const historySlice = createSlice({
    name: 'history',
    initialState: {
        // History
        studentHistory: null,
        studentHistoryStatus: null,
        studentProgramHistory: null,
        studentProgramHistoryStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Student history
        builder
            .addCase(fetchStudentHistory.pending, (state) => {
                state.studentHistoryStatus = 'loading';
            })
            .addCase(fetchStudentHistory.fulfilled, (state, action) => {
                state.studentHistoryStatus = 'success';
                state.studentHistory = action.payload;
            })
            .addCase(fetchStudentHistory.rejected, (state) => {
                state.studentHistoryStatus = 'failed';
            });

        // Fetch Student program history
        builder
            .addCase(fetchStudentProgramHistory.pending, (state) => {
                state.studentProgramHistoryStatus = 'loading';
            })
            .addCase(fetchStudentProgramHistory.fulfilled, (state, action) => {
                state.studentProgramHistoryStatus = 'success';
                state.studentProgramHistory = action.payload;
            })
            .addCase(fetchStudentProgramHistory.rejected, (state) => {
                state.studentProgramHistoryStatus = 'failed';
            });
    }
});

//export const { } = historySlice.actions;

export default historySlice.reducer;