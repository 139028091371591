import { Box, Button, ButtonGroup, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter } from '../../../app/AppUtils';
import { fetchEmployeesForAuthorization, fetchExistingAuthorizations, postEmployeeAuthorizations, resetAttendanceStatus, setFailedAuthorizations } from '../../attendance/attendanceSlice';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function AuthorizationAddCard({ handleClose, performanceId }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { employeesForAuthorization, employeesForAuthorizationStatus, postEmployeeAuthorizationsStatus, showAuthorizationFailed } = useSelector((state) => state.attendance);

    const [filter, setFilter] = useState("");

    useEffect(() => {
        dispatch(fetchEmployeesForAuthorization(performanceId));
    }, [dispatch, performanceId]);

    // Post status callback
    useEffect(() => {
        if (postEmployeeAuthorizationsStatus === "success") {
            dispatch(setFailedAuthorizations(false));
            dispatch(resetAttendanceStatus());
            dispatch(fetchEmployeesForAuthorization(performanceId));
            dispatch(fetchExistingAuthorizations(performanceId));
            handleClose();
        } else if (postEmployeeAuthorizationsStatus === "failed") {
            dispatch(resetAttendanceStatus());
            dispatch(setFailedAuthorizations(true));
        };
    }, [dispatch, handleClose, postEmployeeAuthorizationsStatus, performanceId]);

    // Property states
    const [newAuthorizations, setNewAuthorizations] = useState([]);
    const [errorAuthorizations, setErrorAuthorizations] = useState(null);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newAuthorizations) return setErrorAuthorizations(`${resourceFinal[appLanguage].messages.ERROR_LIST_EMPTY}`);
        setErrorAuthorizations(null);

        var authorizationObject = {
            employeeIds: newAuthorizations,
            performanceId: performanceId,
            createdBy: `AP/${user.number}`
        };

        console.log(authorizationObject)

        dispatch(postEmployeeAuthorizations(authorizationObject));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["fullName", "number"], filter);
    }

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < employeesForAuthorization?.filter(handleFilterResult).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === employeesForAuthorization?.filter(handleFilterResult).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = employeesForAuthorization?.filter(handleFilterResult).map((n) => n.employeeId);
            setNewAuthorizations(newSelected);
            return;
        }
        setNewAuthorizations([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newAuthorizations.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newAuthorizations, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newAuthorizations.slice(1));
        } else if (selectedIndex === newAuthorizations.length - 1) {
            newSelected = newSelected.concat(newAuthorizations.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newAuthorizations.slice(0, selectedIndex),
                newAuthorizations.slice(selectedIndex + 1),
            );
        };

        setNewAuthorizations(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employeesForAuthorization?.filter(handleFilterResult).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => newAuthorizations.findIndex(x => x === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                        <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.EMPLOYEES} {resourceFinal[appLanguage].words.AUTHORIZE.toLowerCase()}</Typography>
                        <ContentLoader successCondition={employeesForAuthorizationStatus === "success"} errorCondition={employeesForAuthorizationStatus === "failed"} content={
                            <>
                                <SearchField func={handleSearchChange} textRef={textRef} />
                                <TableContainer>
                                    <Table>
                                        <EnhancedTableHead numSelected={newAuthorizations.length} onSelectAllClick={handleSelectAllClick} rowCount={employeesForAuthorization?.length} />
                                        <TableBody>
                                            {(rowsPerPage > 0 ? employeesForAuthorization?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employeesForAuthorization?.filter(handleFilterResult))?.map((item, index) => {
                                                const isItemSelected = isSelected(item?.employeeId);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow hover onClick={(event) => handleClick(event, item.employeeId)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item?.employeeId} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                                        </TableCell>
                                                        <TableCell align="center">{item?.number}</TableCell>
                                                        <TableCell align="left">{item?.fullName}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {(rowsPerPage > 0 ? employeesForAuthorization?.filter(handleFilterResult)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employeesForAuthorization?.filter(handleFilterResult))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                            {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    sx={{ border: 0 }}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    count={employeesForAuthorization ? employeesForAuthorization.length : 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                    labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                                    labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                {errorAuthorizations && <Typography variant="body1" color="error" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{errorAuthorizations}</Typography>}
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{resourceFinal[appLanguage].words.EMPLOYEES} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}: {newAuthorizations?.length}</Typography>
                                {showAuthorizationFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                                <ButtonGroup sx={{ justifyContent: 'center' }}>
                                    <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                    <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>
                                </ButtonGroup>
                            </>
                        } />
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}
